import React, { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';
import { encodeLoadoutID } from '../services/armoryCalls';

export default function ArmorySlotSelectors() {
  const [activeLoadoutName, setActiveLoadoutName] = useState();
  const user = useUser();

  const convertSlot = e => {
    const clickedId = e.currentTarget.dataset.id;
    user.setUserActiveSlot(user.activeLoadout, parseInt(clickedId));
    user.setWebCurrentSlot(parseInt(clickedId));
  };

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      let className = encodeLoadoutID(user.activeLoadout);
      setActiveLoadoutName(className);
    }
    return () => {
      didCancel = true;
    };
  }, [user.activeLoadout]);

  const checkPP = () => {
    if (user.hasPP === 1) {
      return (
        <>
          {[
            ['Slot 1', 1],
            ['Slot 2', 2],
            ['Slot 3', 3],
            ['Slot 4', 4],
          ].map(([name, id]) => (
            <li
              onClick={convertSlot}
              data-id={id}
              // className='cursor-pointer bg-gray-700 mr-1 p-1 flex items-center border border-transparent hover:bg-gray-600 transition ease-in-out'
              className={
                user.webCurrentSlot === id
                  ? 'cursor-pointer bg-illYellow-500 mr-1 p-1 flex items-center border border-transparent hover:bg-illYellow-400 transition ease-in-out text-gray-900'
                  : 'cursor-pointer bg-gray-700 mr-1 p-1 flex items-center border border-transparent hover:bg-gray-600 transition ease-in-out md:text-base sm:text-sm'
              }
              key={id}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 mr-1'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z'
                />
              </svg>
              {name}
            </li>
          ))}
        </>
      );
    } else {
      return (
        <div className=' flex items-center'>
          <li
            onClick={convertSlot}
            data-id='1'
            className='cursor-pointer bg-illYellow-500 mr-1 p-1 flex items-center border border-transparent hover:bg-illYellow-400 transition ease-in-out text-gray-900 md:text-base sm:text-sm'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='sm:h-4 sm:w-4 md:h-5 md:w-5 lg:h-6 lg:w-6 md:mr-1'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z'
              />
            </svg>
            Slot 1
          </li>
          <a
            className='uppercase p-1 border-1 border-transparent bg-illOrange-500 hover:bg-illOrange-400 text-gray-100 hover:border-illOrange-900 h-full text-center transition ease-in-out md:text-base sm:text-sm'
            href='#'
          >
            Unlock more with Premium Pass
          </a>
        </div>
      );
    }
  };

  return (
    //   Need to do
    //---User clicks on slot. Convert Active Loadout to name
    <div className='py-2'>
      <div className='flex  justify-between items-center flex-shrink'>
        <div className='sm:w-1/2 md:w-1/3'>
          <ul className='flex justify-start'>
            {/* Set to render only if user has Premium Pass */}
            {checkPP()}
          </ul>
        </div>
        <h2 className='sm:w-1/2 md:w-1/3 uppercase font-bold text-illYellow-500 sm:text-base md:text-lg tracking-wide sm:text-right md:text-center sm:ml-2'>
          {activeLoadoutName}
        </h2>
        <div className=' md:w-1/3'></div>
      </div>
    </div>
  );
}
