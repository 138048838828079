// const { createContext } = require('react');

// export const UserContext = createContext(null);

import React, { Component, createContext, useContext } from 'react';

export const UnlocksContext = createContext();

export function useUnlocks() {
  return useContext(UnlocksContext);
}

class UnlocksContextProvider extends Component {
  state = {
    // unlocks: null,
  };

  setUnlocks = data => {
    this.setState({
      ...data,
    });
  };

  setUpdateOneItem = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <UnlocksContext.Provider
        value={{
          ...this.state,
          setUnlocks: this.setUnlocks,
          setUpdateOneItem: this.setUpdateOneItem,
        }}
      >
        {this.props.children}
      </UnlocksContext.Provider>
    );
  }
}

export default UnlocksContextProvider;
