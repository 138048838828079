// const { createContext } = require('react');

// export const UserContext = createContext(null);

import React, { Component, createContext, useContext, useMemo } from 'react';

export const ItemListContext = createContext();

export function useItemList() {
  return useContext(ItemListContext);
}

class ItemListContextProvider extends Component {
  state = {
    itemList: [],
    vehicleList: [],
  };
  setItemList = data => {
    this.setState({
      // itemList: { ...data },
      itemList: data,
    });
  };

  setVehicleList = data => {
    this.setState({
      // itemList: { ...data },
      vehicleList: data,
    });
  };

  render() {
    return (
      <ItemListContext.Provider
        value={{
          ...this.state,
          setItemList: this.setItemList,
          setVehicleList: this.setVehicleList,
        }}
        // value={{ ...this.state, toggleSim: this.toggleSim }}
      >
        {this.props.children}
      </ItemListContext.Provider>
    );
  }
}

export default ItemListContextProvider;
