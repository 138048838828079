import React, { useEffect, useCallback } from 'react';
import { Navigate } from 'react-router';
import { parseJwt } from '../services/jwtDecoder';
import Axios from 'axios';

// Context imports
// import { AuthContext, useAuth } from '../context/AuthContext';
// import { UserContext, useUser } from '../context/UserContext';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';

const Login = () => {
  const apiURL = process.env.REACT_APP_API_URL;

  const auth = useAuth();
  const user = useUser();

  // let navigate = useNavigate();
  // let location = useLocation();

  // let from = location.state.from.pathname || '/';
  const postLoginFunction = useCallback(userObj => {
    user.setUser(userObj.username, userObj.id, userObj.avatar);
    auth.toggleAuth();
  }, []);

  useEffect(() => {
    // console.log('Login results: ', getApiUser());
    // getApiUser();

    Axios.get(`${process.env.REACT_APP_API_URL}/validate`, {
      withCredentials: true,
    })
      .then(res => {
        if (!res.data[1]) {
          // console.log('Session isn"t valid');
        } else {
          // console.log('user is logged in with cookies');
          // return parseJwt(res.data[0]);
          let userJWT = parseJwt(res.data[0]);
          postLoginFunction(userJWT);
          return <Navigate to='/armory' />;
        }
      })
      .catch(err => console.log(err));
  }, [postLoginFunction]);

  if (auth.isAuthenticated) {
    return <Navigate to='/armory' />;
  }

  return (
    <div className='text-center'>
      <div className='bg-gray-800 w-1/3 flex flex-col mx-auto p-5'>
        <h1 className='text-4xl mt-2 uppercase'>Sign In</h1>
        <h1 className='my-2'>Login to The Armory with Steam</h1>
        <a
          href={`${apiURL}/api/auth/steam`}
          // href={`${`https://illmatik.herokuapp.com`}/api/auth/steam`}
          className=' p-2 flex justify-center'
        >
          {/* <img
            src='https://community.akamai.steamstatic.com/public/images/signinthroughsteam/sits_01.png'
            alt='Sign in through Steam. This site is not associated with Valve Corp'
          /> */}
          <img
            src='https://community.akamai.steamstatic.com/public/images/signinthroughsteam/sits_02.png'
            alt='Sign in through Steam. This site is not associated with Valve Corp'
          />
        </a>
      </div>
    </div>
  );
};

export default Login;
