import React, { useEffect, useState } from 'react';
import ArmoryNavbar from '../components/ArmoryNavbar';

import Axios from 'axios';
import { useUser } from '../context/UserContext';
import StatsCardLG from '../components/statsComponents/StatsCardLG';

// import { getFullLeaderBoard } from '../services/armoryCalls';

export default function Stats() {
  let [test1, setTest1] = useState([]);

  let user = useUser();

  const getFullLeaderBoard = async () => {
    let res = await Axios.get(
      `${process.env.REACT_APP_API_URL}/getleaderboard`
    );
    let data = await res.data;
    console.log(data);

    setTest1(data);
  };

  function secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    // let s = Math.floor(d % 3600 % 60);

    let hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    let mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    return hDisplay + mDisplay;
    // return hDisplay + mDisplay + sDisplay;
  }

  const toHoursAndMinutes = totalMinutes => {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    return `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m`;
  };

  const padTo2Digits = num => {
    return num.toString().padStart(2, '0');
  };

  const calculateKRD = (kills, deaths) => {
    let kdr = kills / deaths;
    return kdr.toPrecision(3);
  };

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      // getFullLeaderBoard();
      // console.log('Test1 State', test1);
    }

    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <div className='flex flex-col min-h-screen max-h-screen'>
      <ArmoryNavbar />
      <div className='container mx-auto'>
        <section className='my-4'>
          <h1 className='text-4xl'>{user.charName}'s Statistics</h1>
          <div className='flex my-4'>
            <img
              src={user.rankURL}
              alt={`${user.charName}'s rank icon`}
              className='h-32'
            />
            <div className='flex flex-col h-full my-auto align-middle '>
              <h3 className='text-2xl font-semibold tracking-wide'>
                {user.rankTitle}
              </h3>
              <p className=''>
                Level: <span className='text-lg'>{user.rankId}</span>
              </p>
              <p className='my-1'>
                {user.rankXP} / {user.rankNextXP}
              </p>
            </div>
          </div>
          <table className='table-auto border-collapse w-full'>
            <thead className='bg-slate-800'>
              <tr>
                <th className='stat-tr'>Name</th>
                <th className='stat-tr'>Kills</th>
                <th className='stat-tr'>Deaths</th>
                <th className='stat-tr'>KDR</th>
                <th className='stat-tr'>Head Shots</th>
                <th className='stat-tr'>Highest Kill Streak</th>
                <th className='stat-tr'>Wins</th>
                <th className='stat-tr'>Losses</th>
                <th className='stat-tr'>Captures</th>
                <th className='stat-tr'>Play Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='stat-td'>{user.charName}</td>
                <td className='stat-td'>{user.kills}</td>
                <td className='stat-td'>{user.deaths}</td>
                <td className='stat-td'>
                  {calculateKRD(user.kills, user.deaths)}
                </td>
                <td className='stat-td'>{user.headshots}</td>
                <td className='stat-td'>{user.killStreak}</td>
                <td className='stat-td'>{user.wins}</td>
                <td className='stat-td'>{user.loss}</td>
                <td className='stat-td'>{user.captures}</td>
                {/* <td className='stat-td'>{secondsToHms(user.minutes)}</td> */}
                {/* <td className='stat-td'>{`${user.minutes} minutes`}</td> */}
                <td className='stat-td'>{toHoursAndMinutes(user.minutes)}</td>
              </tr>
            </tbody>
          </table>
        </section>

        {/* Overview */}
        {/* <section>
          <h2 className='text-3xl'>{user.charName}'s Profile</h2>
          <div className='grid grid-cols-3 gap-1'>
            <StatsCardLG title={'Rank'} />
            <StatsCardLG />
            <StatsCardLG />
          </div>
        </section> */}

        {/* Add at another time Leaderboards down below */}
        {/* <section>
          <h1>Leaderboards Stats</h1>
        </section> */}
      </div>
    </div>
  );
}

// import React, { useEffect } from 'react';
// import Axios from 'axios';

// export default function ArmoryStats() {
//   const getFullLeaderBoard = async () => {
//     let res = await Axios.get(
//       `${process.env.REACT_APP_API_URL}/getleaderboard`
//     );
//     let data = await res.data;
//     console.log(data);
//   };

//   useEffect(() => {
//     let didCancel = false;

//     if (!didCancel) {
//       getFullLeaderBoard();
//     }
//     return () => {
//       didCancel = true;
//     };
//   }, []);

//   return <div>ArmoryStats</div>;
// }
