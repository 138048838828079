import React from 'react';

import { NavLink } from 'react-router-dom';

//   Component

export default function Navbar() {
  const openMobileNav = () => {
    const menu = document.querySelector('.mobile-menu');
    console.log('Open nav');
    menu.classList.toggle('hidden');
  };
  return (
    <header>
      {/* <nav className=' bg-slate-900 '> */}
      <nav className=' bg-charcoal px-4 md:px-0'>
        <div className='container mx-auto'>
          <div className='flex justify-between py-2'>
            <NavLink to='/'>
              <img
                src='/iLLmatik_180x180_White.png'
                alt='iLLmatik Logo'
                className='w-14 h-14'
              />
            </NavLink>
            <div className='flex space-x-4'>
              {/* Logo */}

              {/* <div>
                <a
                  href='#'
                  className='flex items-center py-5 px-2 text-gray-700 hover:text-gray-900'
                >
                  <svg
                    className='h-6 w-6 mr-1 text-blue-400'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M12 19l9 2-9-18-9 18 9-2zm0 0v-8'
                    />
                  </svg>
                  <span className='font-bold'>iLLmatik </span>
                </a>
              </div> */}

              {/* Primary nav */}
              <div className='hidden md:flex items-center'>
                <div className='flex-2'>
                  <NavLink
                    to='/'
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? 'text-illOrange-500'
                          : 'text-gray-300 hover:text-gray-200 '
                      }  select-none p-1 cursor-pointer transition-all ease-in text-base sm:mr-1 md:mr-2`
                    }
                  >
                    Home
                  </NavLink>

                  {/* Old Armory load out link */}
                  {/* <NavLink
                  to='/armory'
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? 'text-illOrange-500'
                        : 'text-gray-300 hover:text-gray-200 '
                    }  select-none p-1 cursor-pointer transition-all ease-in text-base sm:mr-1 md:mr-2`
                  }
                >
                  Armory
                </NavLink> */}

                  <a
                    href='http://shop.illmatik.net/'
                    target='_blank'
                    rel='noreferrer'
                    className='select-none p-1 cursor-pointer transition-all ease-in text-base sm:mr-1 md:mr-2 text-gray-300 hover:text-gray-200 hover:border-gray-200'
                  >
                    Shop
                  </a>
                </div>
              </div>
              {/* <div className='flex items-center justify-center'>
                <img
                  src='iLLmatik_180x180_Orange.png'
                  alt=''
                  className='w-1/2 m-auto'
                />
              </div> */}
            </div>

            {/* user.activeLoadout === id
                  ? 'text-illYellow-500 border-illYellow-500'
                  : 'text-gray-300 border-transparent hover:border-illYellow-500 hover:text-gray-200 '
              } font-bold select-none p-1 border-b-2 cursor-pointer transition-all ease-in sm:text-sm md:text-lg sm:mr-1 md:mr-2`} */}

            {/* Secondary nav */}
            {/* <div className='hidden md:flex items-center space-x-1'>
              <a href='' className='py-5 px-3'>
                Login
              </a>
              <a
                href=''
                className='py-2 px-3 bg-yellow-400 hover:bg-yellow-300 text-yellow-900 hover:text-yellow-800 rounded transition duration-300'
              >
                Signup
              </a>
            </div> */}

            {/* Mobile button */}
            <div
              className='md:hidden flex items-center'
              onClick={openMobileNav}
            >
              <button className='mobile-menu-button'>
                <svg
                  className='w-6 h-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div className='mobile-menu hidden md:hidden'>
          {/* <a href='#' className='block py-2 px-4 text-sm hover:bg-gray-100'>
            Features
          </a>
          <a href='#' className='block py-2 px-4 text-sm hover:bg-gray-100'>
            Pricing
          </a> */}

          <img
            src='/iLLmatik_180x180_White.png'
            alt='iLLmatik Logo'
            className='w-12 h-12 mr-4'
          />
          <NavLink
            to='/'
            className={({ isActive }) =>
              `${
                isActive
                  ? 'text-illOrange-500 border-illOrange-400'
                  : 'text-gray-300 hover:text-gray-200 hover:border-gray-200'
              }  block py-2 px-4 text-sm p-1 cursor-pointer transition-all ease-in border-l-2 border-transparent`
            }
          >
            Home
          </NavLink>

          {/* hides armory from mobile nav */}
          {/* <NavLink
            to='/armory'
            className={({ isActive }) =>
              `${
                isActive
                  ? 'text-illOrange-500 border-illOrange-400'
                  : 'text-gray-300 hover:text-gray-200 hover:border-gray-200'
              }  block py-2 px-4 text-sm p-1 cursor-pointer transition-all ease-in border-l-2 border-transparent`
            }
          >
            Armory
          </NavLink> */}
          <a
            href='http://shop.illmatik.net/'
            target='_blank'
            rel='noreferrer'
            className='select-none p-1 cursor-pointer transition-all ease-in text-base sm:mr-1 md:mr-2'
          >
            Shop
          </a>
        </div>
      </nav>

      {/* Sample Codepen Nav */}
      {/* <nav className='bg-gray-100'>
        <div className='max-w-6xl mx-auto px-4'>
          <div className='flex justify-between'>
            <div className='flex space-x-4'>
              Logo
              <div>
                <a
                  href='#'
                  className='flex items-center py-5 px-2 text-gray-700 hover:text-gray-900'
                >
                  <svg
                    className='h-6 w-6 mr-1 text-blue-400'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M12 19l9 2-9-18-9 18 9-2zm0 0v-8'
                    />
                  </svg>
                  <span className='font-bold'>Better Dev</span>
                </a>
              </div>

              Primary nav
              <div className='hidden md:flex items-center space-x-1'>
                <a
                  href='#'
                  className='py-5 px-3 text-gray-700 hover:text-gray-900'
                >
                  Features
                </a>
                <a
                  href='#'
                  className='py-5 px-3 text-gray-700 hover:text-gray-900'
                >
                  Pricing
                </a>
              </div>
            </div>

            Secondary nav
            <div className='hidden md:flex items-center space-x-1'>
              <a href='' className='py-5 px-3'>
                Login
              </a>
              <a
                href=''
                className='py-2 px-3 bg-yellow-400 hover:bg-yellow-300 text-yellow-900 hover:text-yellow-800 rounded transition duration-300'
              >
                Signup
              </a>
            </div>

            Mobile button
            <div
              className='md:hidden flex items-center'
              onClick={openMobileNav}
            >
              <button className='mobile-menu-button'>
                <svg
                  className='w-6 h-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        Mobile menu
        <div className='mobile-menu hidden md:hidden'>
          <a href='#' className='block py-2 px-4 text-sm hover:bg-gray-200'>
            Features
          </a>
          <a href='#' className='block py-2 px-4 text-sm hover:bg-gray-200'>
            Pricing
          </a>
        </div>
      </nav> */}
    </header>
  );
}
