import { useLocation, Navigate } from 'react-router';
import { useAuth } from './AuthContext';

export default function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} />;
  }
  return children;
}
