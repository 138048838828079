import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import { useUser } from '../context/UserContext';
import { useItemList } from '../context/ItemListContext';
import { useLoadout } from '../context/LoadoutContext';
import { useUnlocks } from '../context/UnlocksContext';

// Components
import ArmoryNavbar from '../components/ArmoryNavbar';
// API Functions
import { decodeActiveLoadout } from '../services/armoryCalls';
import ArmoryClassSelectors from '../components/ArmoryClassSelectors';
import ArmoryLoadoutDisplay from '../components/ArmoryLoadoutDisplay';
import ArmorySlotSelectors from '../components/ArmorySlotSelectors';
import ArmoryGearSelection from '../components/ArmoryGearSelection';
import ArmoryLoadoutUpdateBtn from '../components/ArmoryLoadoutUpdateBtn';
// import ArmoryNavbarDropdown from '../components/ArmoryNavbarDropdown';
import ArmoryUserNotFound from '../components/ArmoryUserNotFound';
// import ArmoryLowBalanceModal from '../components/ArmoryLowBalanceModal';
import LoadingScreen from '../components/LoadingScreen';

// import { useCookies } from 'react-cookie';

const Armory = () => {
  // Defining state

  // Web state... Idea is to use it for updating the DB
  // const [webActiveLoadout, setWebActiveLoadout] = useState();
  // const [webActiveSlot, setWebActiveSlot] = useState();
  // const [isLoading, setLoading] = useState(false);

  // const apiURL = process.env.REACT_APP_API_URL;
  const user = useUser();
  const itemList = useItemList();
  const loadoutCon = useLoadout();
  const userUnlocks = useUnlocks();

  const [isUserActive, setIsUserActive] = useState(true);
  const [loading, setLoading] = useState(true);

  const [tempItemList, setTempItemList] = useState();
  const [tempVehicleList, setTempVehicleList] = useState();

  // Functions for useEffects ___________________________________________________________________________

  // Loops over activeLoadout query results to look for ItemContext skus. Then adds sku's properties to a new temp obj to be then placed in loadoutContext
  const changeLoadoutStructure = result => {
    // console.log(`changeLoadoutStructure`, result);

    let tempObj = {};
    for (const gear in result) {
      itemList &&
        itemList.itemList.forEach(item => {
          if (result[gear] === item.SKU && gear !== 'VehID') {
            // console.log(gear, result[gear]);
            tempObj[gear] = {
              SKU: item.SKU,
              uName: item.uName,
              URL1: item.URL1,
              URL2: item.URL2,
              Cat: item.Cat,
              Type: item.Type,
            };
          }
          if (gear === 'VehID' && result['HasVeh'] === 0) {
            tempObj[gear] = {
              SKU: 0,
              uName: 'Empty',
              URL1: 'https://i.imgur.com/SJK3MHg.png',
              URL2: 'https://i.imgur.com/SJK3MHg.png',
              Cat: 'All',
              Type: 'All',
            };
          }
          //new code added into to prevent undefined on VehID in loadoutCon in PROD
          // else if (gear === 'HasVeh' && result[gear] === 1) {
          //   // console.log(gear, result[gear]);
          //   itemList &&
          //     itemList.vehicleList.forEach(vehicle => {
          //       if (result['VehID'] === vehicle.SKU) {
          //         console.table(result['VehID'], vehicle.SKU, vehicle.uName);
          //         tempObj['VehID'] = {
          //           SKU: vehicle.SKU,
          //           uName: vehicle.uName,
          //           URL1: vehicle.URL1,
          //           URL2: vehicle.URL2,
          //           Cat: vehicle.Cat,
          //           Type: vehicle.Type,
          //           Class: vehicle.Class,
          //         };
          //       }
          //     });
          // }
        });

      // if (gear === 'HasVeh' && result[gear] === 1) {
      if (gear === 'HasVeh' && result[gear] === 1) {
        console.log(gear, result[gear]);
        itemList &&
          itemList.vehicleList.forEach(vehicle => {
            if (result['VehID'] === vehicle.SKU) {
              console.table(result['VehID'], vehicle.SKU, vehicle.uName);
              tempObj['VehID'] = {
                SKU: vehicle.SKU,
                uName: vehicle.uName,
                URL1: vehicle.URL1,
                URL2: vehicle.URL2,
                Cat: vehicle.Cat,
                Type: vehicle.Type,
                Class: vehicle.Class,
              };
            }
          });
      }

      // }
      // if (gear === 'HasVeh' && result[gear] === 1) {
      //   itemList &&
      //     itemList.vehicleList.forEach(vehicle => {
      //       if (result['VehID'] === vehicle.SKU) {
      //         tempObj['VehID'] = {
      //           SKU: vehicle.SKU,
      //           uName: vehicle.uName,
      //           URL1: vehicle.URL1,
      //           URL2: vehicle.URL2,
      //           Cat: vehicle.Cat,
      //           Type: vehicle.Type,
      //           Class: vehicle.Class,
      //         };
      //       }
      //     });
      // }
    }
    loadoutCon.setLoadout(tempObj);
  };

  // --------------------------=======================================================================================================
  // Gets users Loadout from the args passed. Sets loadout Context once retrieves data
  const getActiveLoadout = async (steamID, loadout, slot) => {
    let decoded = decodeActiveLoadout(loadout);
    console.log(`get active loadout vars:`, steamID, decoded, slot);
    console.log(
      'getActiveLoadout Function has run *******************************'
    );

    let res = await Axios.get(`${process.env.REACT_APP_API_URL}/getloadout`, {
      params: {
        ID: steamID,
        table: decoded,
        slotID: slot,
      },
    });
    // console.log(`getActiveLoadout res.data ${res.data[0]}`, res.data[0]);
    let data = res.data[0];
    console.log(data);
    changeLoadoutStructure(data);
  };

  // ______________________________________________________________________________________________
  // End of useEffect's functions

  // Function to display content conditionally

  const displayArmory = () => {
    if (!loading) {
      if (isUserActive) {
        return (
          <>
            <ArmoryNavbar name={user.steamUsername} avatar={user.steamAvatar} />
            <div className='container mx-auto'>
              <section className='armory_loadouts_maker flex-grow'>
                <ArmoryClassSelectors />
                <ArmorySlotSelectors />
                <div className='armory_current_loadout grid gap-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2'>
                  <ArmoryLoadoutDisplay />
                  <ArmoryGearSelection />
                </div>
                <ArmoryLoadoutUpdateBtn></ArmoryLoadoutUpdateBtn>
              </section>
            </div>
          </>
        );
      } else {
        return <ArmoryUserNotFound />;
      }
    } else {
      return <LoadingScreen />;
    }
  };

  const setSteamUser = data => {
    if (data.length > 0) {
      user.setUserStats(data[0]);
      setIsUserActive(true);
    } else {
      setIsUserActive(false);
    }
  };

  const setUsersUnlocks = data => {
    data.forEach(item => {
      userUnlocks.setUnlocks({ ...item });
    });
  };

  // const getUserLoadout = () => {
  //   let slotClassName = 'slot_C' + user.activeLoadout;
  //   let activeSlot = user[slotClassName];

  //   // reset slot back to 1 if the user's slot hasn't reverted to 1 in-game. This restricts them from editing a slot that is restricted to PP.
  //   if (user.hasPP === 0 && activeSlot > 1) {
  //     activeSlot = 1;
  //   }
  //   user.setWebCurrentSlot(activeSlot);
  //   if (user.activeLoadout !== null) {
  //     getActiveLoadout(user.steamID, user.activeLoadout, activeSlot);
  //     // getActiveLoadout(user.steamID, user.activeLoadout, user.webCurrentSlot);
  //   } else {
  //     // console.log(
  //     //   `userActiveloadOut was null, didn't run the function getActiveLoadout()`
  //     // );
  //   }
  // };

  const fetchBasicData = () => {
    let allItemList;
    let allVehicleList;
    let allSteamUser;
    let allUserUnlocks;
    // API Calls
    const getItemList = Axios.get(
      `${process.env.REACT_APP_API_URL}/getitemslist`
    );
    const getVehicleList = Axios.get(
      `${process.env.REACT_APP_API_URL}/getvehicleslist`
    );
    const getSteamUser = Axios.get(
      `${process.env.REACT_APP_API_URL}/userstats`,
      {
        params: {
          ID: user.steamID,
        },
      }
    );
    const getUserUnlocks = Axios.get(
      `${process.env.REACT_APP_API_URL}/getunlocks`,
      {
        params: {
          ID: user.steamID,
        },
      }
    );

    // Axios Call(s)
    Axios.all([getItemList, getVehicleList, getSteamUser, getUserUnlocks]).then(
      Axios.spread((...allData) => {
        allItemList = allData[0].data;
        allVehicleList = allData[1].data;
        allSteamUser = allData[2].data;
        allUserUnlocks = allData[3].data;

        // setting up/changing Contexts'

        // Item Lists + Vehicle Lists
        itemList.setItemList(allItemList);
        itemList.setVehicleList(allVehicleList);

        // User's details
        setSteamUser(allSteamUser);
        setUsersUnlocks(allUserUnlocks);
      })
    );
  };

  // const testFunct = () => {
  //   // console.log(
  //   //   '============================================== TEST FUNCTION RAN AFTER THE AXIOS CALL USING THE .THEN'
  //   // );
  // };

  const testAsync = () => {
    return new Promise((resolve, reject) => {
      //here our function should be implemented
      setTimeout(() => {
        console.log(
          '$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Hello from inside the testAsync function'
        );
        resolve();
      }, 1250);
    });

    // .then(console.log('this is another then after all thje logs'))
  };

  const handleLoading = () => {
    return new Promise((resolve, reject) => {
      //here our function should be implemented
      setTimeout(() => {
        console.log(
          '$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Hello from inside the testAsync function'
        );

        if (itemList.itemList !== undefined && user.steamID !== null) {
          setLoading(false);
        } else {
          setLoading(true);
        }
        resolve();
      }, 1500);
    });
  };

  async function callerFun() {
    console.log('____________________________________________________Caller');
    await testAsync();
    console.log('__________________________________After waiting');
  }

  // CallbackFunction after FetchBasicData

  // ###########################################################################################################################################################################
  // ############################################################           USE EFFECTS               ##########################################################################
  // ###########################################################################################################################################################################

  // useEffects that I think should be called before loadouts

  // New useEffect for test to get all items at once

  useEffect(() => {
    fetchBasicData();

    handleLoading();
    // getUserLoadout();

    // callerFun();
  }, []);

  // On page load get activeLoadout Function to run, reruns on activeLoadout or webCurrentSlot context update
  useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      // console.log(
      //   '============================================= getActiveLoadout UseEffect Running'
      // );

      // adds ActiveLoadout to the Class slot number to convert it back into slot name
      //
      console.log(
        `useEffect userActiveLoadout::: ${user.activeLoadout} ||| slot_C ${user.activeLoadout}`
      );

      let slotClassName = 'slot_C' + user.activeLoadout;
      let activeSlot = user[slotClassName];

      // reset slot back to 1 if the user's slot hasn't reverted to 1 in-game. This restricts them from editing a slot that is restricted to PP.
      if (user.hasPP === 0 && activeSlot > 1) {
        activeSlot = 1;
      }
      user.setWebCurrentSlot(activeSlot);
      if (user.activeLoadout !== null) {
        getActiveLoadout(user.steamID, user.activeLoadout, activeSlot);
        // getActiveLoadout(user.steamID, user.activeLoadout, user.webCurrentSlot);
      }
    }
    return () => {
      didCancel = true;
    };
  }, [user.activeLoadout, user.webCurrentSlot]);

  return <div className='armory text-center mb-auto'>{displayArmory()}</div>;
};

export default Armory;
