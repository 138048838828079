import React from 'react';

export default function Footer() {
  const copyrightYear = () => {
    const d = new Date();
    let year = d.getFullYear();
    return year;
  };

  return (
    // Add site nav when website is finished
    <footer className=' text-black mt-10 mx-auto container'>
      <div className=' border-t border-gray-800 text-center p-2 flex justify-between'>
        <p className='text-sm text-gray-300'>
          &copy; {copyrightYear()} iLLmatik. All Rights Reserved
        </p>
        <p className='text-sm text-gray-400'>Version: 1.0.09</p>
      </div>
    </footer>
  );
}
