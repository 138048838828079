// const { createContext } = require('react');

// export const UserContext = createContext(null);

import React, { Component, createContext, useContext } from 'react';

export const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

class UserContextProvider extends Component {
  state = {
    steamUsername: null,
    steamID: null,
    steamAvatar: null,
    // Start of mySQL response data
    lastEdit: null,
    activeLoadout: null,
    accountCreation: null,
    credits: null,
    kills: null,
    deaths: null,
    headshots: null,
    hasPP: null,
    rankId: null,
    rankTitle: null,
    rankXP: null,
    rankNextID: null,
    rankNextTitle: null,
    rankNextXP: null,
    rankURL: null,
    rankNextURL: null,
    slot_C1: 1,
    slot_C2: 1,
    slot_C3: 1,
    slot_C4: 1,
    slot_C5: 1,
    slot_C6: 1,
    slot_C7: 1,
    slot_C8: 1,
    slot_C9: 1,
    slot_C10: 1,
    slot_C11: 1,
    slot_C12: 1,
    slot_C13: 1,
    slot_C14: 1,
    slot_C15: 1,
    slot_C16: 1,
    slot_C17: 1,
    slot_C18: 1,
    slot_C19: 1,
    slot_C20: 1,

    // start of custom web state
    webCurrentSlot: 1,
  };
  setUser = (username, id, avatar) => {
    this.setState({
      steamUsername: username,
      steamID: id,
      steamAvatar: avatar,
    });
  };

  setUserStats = data => {
    this.setState({
      lastEdit: data.Accessed,
      activeLoadout: data.ActiveLoadout,
      accountCreation: data.Created,
      charName: data.CharName,
      credits: data.Credit,
      deaths: data.Deaths,
      headshots: data.Headshots,
      kills: data.Kills,
      killStreak: data.Killstreak,
      captures: data.Captures,
      wins: data.Wins,
      loss: data.Loss,
      minutes: data.Minutes,
      tks: data.TKs,
      ppAct: data.PP_Act,
      ppExpire: data.PP_Expire,
      hasPP: data.PP_Has,
      rankId: data.RankID,
      rankTitle: data.RankTitle,
      rankXP: data.XP,
      rankNextID: data.RankNextID,
      rankNextTitle: data.RankNextTitle,
      rankNextXP: data.RankNextXP,
      rankURL: data.RankURL,
      rankNextURL: data.RankNextURL,
      slot_C1: data.Slot_C1,
      slot_C2: data.Slot_C2,
      slot_C3: data.Slot_C3,
      slot_C4: data.Slot_C4,
      slot_C5: data.Slot_C5,
      slot_C6: data.Slot_C6,
      slot_C7: data.Slot_C7,
      slot_C8: data.Slot_C8,
      slot_C9: data.Slot_C9,
      slot_C10: data.Slot_C10,
      slot_C11: data.Slot_C11,
      slot_C12: data.Slot_C12,
      slot_C13: data.Slot_C13,
      slot_C14: data.Slot_C14,
      slot_C15: data.Slot_C15,
      slot_C16: data.Slot_C16,
      slot_C17: data.Slot_C17,
      slot_C18: data.Slot_C18,
      slot_C19: data.Slot_C19,
      slot_C20: data.Slot_C20,
      slot_C21: data.Slot_C21,
      slot_C22: data.Slot_C22,
    });
  };
  setUserActiveLoadout = newActiveLoadout => {
    this.setState({
      activeLoadout: newActiveLoadout,
    });
  };

  setUserActiveSlot = (activeLoadout, newSlot) => {
    let loadoutName = 'slot_C' + activeLoadout;
    this.setState({
      [loadoutName]: parseInt(newSlot),
    });
  };

  setWebCurrentSlot = slot => {
    this.setState({
      webCurrentSlot: slot,
    });
  };

  setUpdatedCredit = value => {
    this.setState({
      credits: value,
    });
  };
  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          setUser: this.setUser,
          setUserStats: this.setUserStats,
          setUserActiveLoadout: this.setUserActiveLoadout,
          setUserActiveSlot: this.setUserActiveSlot,
          setWebCurrentSlot: this.setWebCurrentSlot,
          setUpdatedCredit: this.setUpdatedCredit,
        }}
        // value={{ ...this.state, toggleSim: this.toggleSim }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserContextProvider;
