import React from 'react';
import { Outlet } from 'react-router';
import Footer from './Footer';
import Navbar from './Navbar';

export default function Layout() {
  return (
    <div className='App flex flex-col min-h-screen justify-between bg-charcoal text-gray-400'>
      {/* <div className='App flex flex-col min-h-screen justify-between bg-gradient-to-tr from-gray-900 via-gray-900 to-gray-800 text-gray-400'> */}
      {/* <div className='App flex flex-col min-h-screen justify-between bg-zinc-800 text-gray-400'> */}
      {/* <div className='App flex flex-col min-h-screen bg-gradient-to-tr from-illBlue-600 to-illBlue-500'> */}
      {/* // <div className='App flex flex-col min-h-screen bg-gray-900'> */}
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}
