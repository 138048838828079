// const { createContext } = require('react');

// export const UserContext = createContext(null);

import React, { Component, createContext, useContext } from 'react';

export const LoadoutContext = createContext();

export function useLoadout() {
  return useContext(LoadoutContext);
}

class LoadoutContextProvider extends Component {
  state = {
    Bag: null,
    Bottom: null,
    ClassID: null,
    Gadget1: null,
    Gadget2: null,
    HasVeh: null,
    Hat: null,
    Melee: null,
    P_Barrel: null,
    P_Grip: null,
    P_Sight: null,
    P_Tactical: null,
    Prime: null,
    S_Barrel: null,
    S_Sight: null,
    Secondary: null,
    SlotID: null,
    Top: null,
    Throw1: null,
    Throw2: null,
    VehID: null,
    Vest: null,
    Vision: null,

    webGearSearch: {
      cat: null,
      type: null,
    },

    loadoutItemKey: null,
  };

  setLoadout = data => {
    this.setState({
      // loadout: data,

      Bag: data.Bag,
      Bottom: data.Bottom,
      ClassID: data.ClassID,
      Gadget1: data.Gadget1,
      Gadget2: data.Gadget2,
      HasVeh: data.HasVeh,
      Hat: data.Hat,
      Melee: data.Melee,
      P_Barrel: data.P_Barrel,
      P_Grip: data.P_Grip,
      P_Sight: data.P_Sight,
      P_Tactical: data.P_Tactical,
      Prime: data.Prime,
      S_Barrel: data.S_Barrel,
      S_Sight: data.S_Sight,
      Secondary: data.Secondary,
      SlotID: data.SlotID,
      Top: data.Top,
      Throw1: data.Throw1,
      Throw2: data.Throw2,
      VehID: data.VehID,
      Vest: data.Vest,
      Vision: data.Vision,
    });
  };

  setWebGearSearch = (cat, type) => {
    this.setState({
      webGearSearch: {
        cat,
        type,
      },
    });
  };

  setLoadoutItemKey = item => {
    this.setState({
      loadoutItemKey: item,
    });
  };

  setUpdateOneItem = (key, newItem) => {
    this.setState({
      [key]: {
        // ...newItem.Cat,
        // ...newItem.SKU,
        // ...newItem.Type,
        // ...newItem.URL1,
        // ...newItem.URL2,
        // ...newItem.uName,
        Cat: newItem.Cat,
        SKU: newItem.SKU,
        Type: newItem.Type,
        URL1: newItem.URL1,
        URL2: newItem.URL2,
        uName: newItem.uName,
        // },
      },
      // },
      // loadout.Secondary.SKU: 52
      // loadout[key].
      // Cat: newItem.Cat,
      //   SKU: newItem.SKU,
      //   Type: newItem.Type,
      //   URL1: newItem.URL1,
      //   URL2: newItem.URL2,
      //   uName: newItem.uName,
      // },
    });
  };
  render() {
    return (
      <LoadoutContext.Provider
        value={{
          ...this.state,
          setLoadout: this.setLoadout,
          setWebGearSearch: this.setWebGearSearch,
          setLoadoutItemKey: this.setLoadoutItemKey,
          setUpdateOneItem: this.setUpdateOneItem,
        }}
        // value={{ ...this.state, toggleSim: this.toggleSim }}
      >
        {this.props.children}
      </LoadoutContext.Provider>
    );
  }
}

export default LoadoutContextProvider;
