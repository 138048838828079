import React from 'react';

const UnlockItemCard = props => {
  console.log(`UnlockItemCard Called`);

  let item = props.item;
  return (
    <div className='flex bg-slate-800' key={item.key}>
      <h2 className='text-lg mr-3'>{item.uName}</h2>
      <h2 className='text-lg'>Level: {item.Lvl}</h2>
      <img
        src={item.URL1}
        alt={item.uName}
        title={item.uName}
        className='h-8 mx-auto'
      />
      {/* <h2 className='text-lg'>{props.item.SKU}</h2> */}
      {/* <h2 className='text-lg'>{props.item}</h2>
      <h2 className='text-lg'>{props.item.lvl}</h2> */}
      {/* <h3 className='text-lg'>{props.lvl}</h3> */}
    </div>
  );
};
export default UnlockItemCard;
