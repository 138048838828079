import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

export default function ArmoryLowBalanceModal({ stateChanger, ...props }) {
  let [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      {/* <div className='fixed inset-0 flex items-center justify-center'>
        <button
          type='button'
          onClick={openModal}
          className='px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
        >
          Open dialog
        </button>
      </div> */}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          // onClose={closeModal}
          // onClick={() => {
          //   stateChanger(false);
          // }}
          onClose={() => stateChanger(false)}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-700 shadow-xl rounded-2xl'>
                <Dialog.Title
                  as='h3'
                  className='text-xl font-medium leading-6 text-gray-100'
                >
                  {/* Payment successful */}
                  Insufficient War Bonds
                </Dialog.Title>
                <div className='mt-2'>
                  {/* <p className='text-sm text-gray-500'>
                    Your payment has been successfully submitted. We’ve sent you
                    an email with all of the details of your order.
                  </p> */}
                  <p className='text-sm text-gray-300'>
                    You don't have enough War Bonds to purchase this item.{' '}
                  </p>
                  {/* <p className='text-sm text-gray-500'>{props.msg}</p> */}
                  {/* <div className={`bg-${props.color} h-5 w-full`}></div> */}
                </div>

                <div className='mt-4'>
                  <button
                    type='button'
                    className='inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-100 bg-illOrange-500 border border-transparent rounded-md hover:bg-illOrange-400 focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-illOrange-600'
                    // onClick={closeModal}
                    onClick={() => {
                      stateChanger(false);
                      // closeModal;
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
