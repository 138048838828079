import React from 'react';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';
import Axios from 'axios';
import ArmoryNavbarDropdown from './ArmoryNavbarDropdown';

import { NavLink } from 'react-router-dom';

export default function ArmoryNavbar(props) {
  const user = useUser();
  const auth = useAuth();

  const handleLogout = () => {
    const logoutUser = async () => {
      let res = await Axios.get(`${process.env.REACT_APP_API_URL}/logout`);
      let data = await res.data;
      // console.log('Logged user out.... ', data);
    };
    console.log('handleLogout ran');

    logoutUser();
  };

  return (
    <nav className='  bg-gray-800 p-2 align-middle flex justify-between items-center'>
      <div className='container mx-auto flex justify-between items-center'>
        <ul>
          {/* <li className=''>Armory</li>
        <li>Statistics</li> */}
          <NavLink
            to='/armory'
            className={({ isActive }) =>
              `${
                isActive
                  ? 'text-illYellow-500'
                  : 'text-gray-300 hover:text-gray-200 '
              }  select-none p-1 cursor-pointer transition-all ease-in text-base sm:mr-1 md:mr-2`
            }
          >
            Armory
          </NavLink>
          <NavLink
            to='/stats'
            className={({ isActive }) =>
              `${
                isActive
                  ? 'text-illYellow-500'
                  : 'text-gray-300 hover:text-gray-200 '
              }  select-none p-1 cursor-pointer transition-all ease-in text-base sm:mr-1 md:mr-2`
            }
          >
            Stats
          </NavLink>
          {/* <NavLink
            to='/unlocks'
            className={({ isActive }) =>
              `${
                isActive
                  ? 'text-illYellow-500'
                  : 'text-gray-300 hover:text-gray-200 '
              }  select-none p-1 cursor-pointer transition-all ease-in text-base sm:mr-1 md:mr-2`
            }
          >
            Unlocks
          </NavLink> */}
        </ul>
        {/* <div className='flex items-center'>
        <p>Warbonds: {user.credits}</p>
        <p>Level: {user.rankId}</p>
        <div className='flex items-center'>
          <img
            src={user.rankURL}
            alt={`${user.name}'s current rank icon`}
            className='h-8'
          />
          <p>{user.rankTitle}</p>
        </div>
        <li>{props}</li>
      </div> */}
        {/* <div>
        {auth.isAuthenticated && (
          <p onClick={handleLogout} className='cursor-pointer'>
            Logout
          </p>
        )}
      </div> */}
        <div className='flex items-center text-gray-300'>
          <div className='flex items-center mr-2 lg:mr-4 '>
            <p className='mr-4'>
              <span className='font-bold uppercase'>War Bonds:</span>{' '}
              {user.credits}
            </p>
            <p className=''>
              <span className='font-bold uppercase'>LVL:</span> {user.rankId}
            </p>
            <img
              src={user.rankURL}
              alt={`${user.name}'s current rank icon`}
              title={user.rankTitle}
              className='h-8 mx-1 hidden md:inline-block'
            />
            <p className='hidden lg:inline-block'>{user.rankTitle}</p>
          </div>
          <ArmoryNavbarDropdown />
        </div>
      </div>

      {/* Left: Armory/Stats  */}
    </nav>
  );
}
