import React from 'react';

export default function pwm() {
  return (
    <div>
      <h1>Plastic Warfare Modern</h1>
      <p>Server IP: 104.192.227.58</p>
      <p>Server Port: 27032</p>
    </div>
  );
}
