import React from 'react';
import ArmoryCardPrimAttch from './ArmoryCardPrimAttch';
import { useLoadout } from '../context/LoadoutContext';

export default function ArmoryItemCardLgSec(props) {
  const loadoutCon = useLoadout();

  const handleClick = e => {
    const clickedItemCat = e.target.dataset.cat;
    const clickedItemType = e.target.dataset.type;
    const clickedLoadoutKey = e.target.dataset.lkey;

    loadoutCon.setWebGearSearch(clickedItemCat, clickedItemType);
    loadoutCon.setLoadoutItemKey(clickedLoadoutKey);
  };

  return (
    // <div className='armory_card_lg w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-1 md:px-1 md:w-1/2 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2'>
    // <div className='armory_card_lg w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-1 md:px-1 md:w-1/2 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2'>
    <div className='armory_card_lg w-full border border-gray-800'>
      <div className='armory_card_main'>
        <div className='armory_card_title bg-gray-800 uppercase text-gray-200 text-left flex justify-between items-center p-1'>
          <p className='font-bold tracking-wide'> {props.title}</p>
          <p className='sm:text-sm lg:text-base'> {props.name}</p>
        </div>
        <div className='bg-gray-900 border-b border-gray-800'>
          <img
            src={`${props.icon1}`}
            alt={`${props.title} icon`}
            title={props.name}
            className='h-32 mx-auto hover:opacity-80 transition ease-in cursor-pointer '
            onClick={handleClick}
            data-cat={props.cat1}
            data-type={props.type1}
            data-lkey={props.loadoutName1}
          />
        </div>
      </div>

      {/* <div className='armory_card_attachments flex justify-between mt-1'> */}
      <div className='armory_card_attachments grid grid-cols-2  border-gray-800 divide-x divide-gray-800'>
        <ArmoryCardPrimAttch
          title={'Sight'}
          name={loadoutCon.S_Sight.uName}
          URL1={loadoutCon.S_Sight.URL1}
          sku1={loadoutCon.S_Sight.SKU}
          cat1={'S_Sight'}
          type1={'Sight'}
          loadoutName1={'S_Sight'}
        />
        <ArmoryCardPrimAttch
          title={'Barrel'}
          name={loadoutCon.S_Barrel.uName}
          URL1={loadoutCon.S_Barrel.URL1}
          sku1={loadoutCon.S_Barrel.SKU}
          cat1={'S_Barrel'}
          type1={'Barrel'}
          loadoutName1={'S_Barrel'}
        />
        {/* <ArmoryCardPrimAttch
          title={'Barrel'}
          name={loadoutCon.loadout.P_Barrel.uName}
          URL1={loadoutCon.loadout.P_Barrel.URL1}
          sku1={loadoutCon.loadout.P_Barrel.SKU}
        />
        <ArmoryCardPrimAttch
          title={'Tactical'}
          name={loadoutCon.loadout.P_Tactical.uName}
          URL1={loadoutCon.loadout.P_Tactical.URL1}
          sku1={loadoutCon.loadout.P_Tactical.SKU}
        /> */}
      </div>
    </div>
  );
}
