import React, { useState } from 'react';
import Axios from 'axios';
import { useLoadout } from '../context/LoadoutContext';
import { useUnlocks } from '../context/UnlocksContext';
import { useUser } from '../context/UserContext';
import { updateUnlockList, updateUserCredits } from '../services/armoryCalls';
import ArmoryLowBalanceModal from './ArmoryLowBalanceModal';

export default function ArmoryGearCard(props) {
  const [showModal, setShowModal] = useState(false);
  const [modalDet, setModalDet] = useState({
    modalDet: {
      title: '',
      msg: '',
      color: '',
    },
  });
  const loadoutCon = useLoadout();
  const unlocks = useUnlocks();
  const user = useUser();

  const handleChangeItem = e => {
    const clickedItem = {
      Key: loadoutCon.loadoutItemKey,
      Cat: e.target.dataset.cat,
      SKU: e.target.dataset.sku,
      Type: e.target.dataset.type,
      URL1: e.target.dataset.icon1,
      URL2: e.target.dataset.icon2,
      uName: e.target.dataset.name,
    };

    loadoutCon.setUpdateOneItem(loadoutCon.loadoutItemKey, clickedItem);
  };

  const unlockItem = async () => {
    let value = 1;
    let objName = `U${props.lvl}`;

    Axios.get(`${process.env.REACT_APP_API_URL}/checkcredits`, {
      params: {
        ID: user.steamID,
      },
    })
      .then(res => {
        user.setUpdatedCredit(res.data[0].Credit);
        let tempBalance = res.data[0].Credit;

        if (props.cost > 0) {
          let newBalance = tempBalance - props.cost;
          if (newBalance < 0) {
            setModalDet({
              modalDet: {
                title: 'Test',
                msg: 'Hey his worked',
                color: 'red-800',
              },
            });

            setShowModal(true);
          } else {
            unlocks.setUpdateOneItem(objName, value);
            updateUnlockList(user.steamID, objName, value);
            user.setUpdatedCredit(newBalance);
            updateUserCredits(user.steamID, newBalance);
          }
        }
      })
      .catch(err => console.log(err));
  };

  const computeBothMode = playersLockType => {
    if (playersLockType === 0) {
      return (
        <div className='h-8 flex justify-between items-center'>
          <p className='uppercase text-gray-300 text-sm pl-1'>
            Req LVL: {props.lvl}
          </p>
          <p className='bg-illOrange-500 p-1 text-gray-100 tracking-wide uppercase cursor-not-allowed flex justify-center items-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5 mr-1'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z'
              />
            </svg>
            Locked
          </p>
        </div>
      );
    }
    if (playersLockType === 2) {
      return (
        <div className='h-8 flex justify-between items-center'>
          <p className='uppercase text-gray-300 text-sm pl-1'>
            Cost: {props.cost} WB
          </p>
          <p
            href='#'
            className='bg-illOrange-500 text-white p-1 font-bold tracking-wide uppercase cursor-pointer hover:bg-illOrange-400 transition ease-in'
            onClick={unlockItem}
          >
            Buy Now
          </p>
        </div>
      );
    }
  };

  const changeImg = e => {
    let icon1 = props.icon1;
    let icon2 = props.icon2;
    if (icon2 === '') {
      icon2 += icon1;
    }
    e.target.src = icon2;
  };

  const resetImg = e => {
    let icon1 = props.icon1;
    e.target.src = icon1;
  };

  const returnNull = () => {
    return null;
  };

  const checkEquipped = () => {
    if (parseInt(loadoutCon[loadoutCon.loadoutItemKey].SKU) === props.sku) {
      return (
        <>
          <div className='group bg-gray-900'>
            <img
              src={props.icon1}
              alt={`${props.name} icon`}
              title={props.name}
              className='rounded-t-lg w-24 h-20 mx-auto cursor-pointer hover:opacity-80 transition ease-in-out'
              data-name={props.name}
              data-sku={props.sku}
              data-icon1={props.icon1}
              data-icon2={props.icon2}
              data-cat={props.cat}
              data-type={props.type}
              onMouseEnter={changeImg}
              onMouseLeave={resetImg}
            />
          </div>

          <div className='h-8 flex justify-between items-center'>
            <p
              className='bg-illBlue-100 text-gray-100 tracking-wide p-1 uppercase w-full transition ease-in cursor-pointer'
              data-name={props.name}
              data-sku={props.sku}
              data-icon1={props.icon1}
              data-icon2={props.icon2}
              data-cat={props.cat}
              data-type={props.type}
            >
              Equipped
            </p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className='group bg-gray-900'>
            <img
              src={props.icon1}
              alt={`${props.name} icon`}
              title={props.name}
              className='rounded-t-lg w-24 h-20 mx-auto cursor-pointer hover:opacity-80 transition ease-in-out'
              data-name={props.name}
              data-sku={props.sku}
              data-icon1={props.icon1}
              data-icon2={props.icon2}
              data-cat={props.cat}
              data-type={props.type}
              onClick={handleChangeItem}
              onMouseEnter={changeImg}
              onMouseLeave={resetImg}
            />
          </div>

          <div className='h-8 flex justify-between items-center'>
            <p
              className='bg-illYellow-500 text-gray-900 tracking-wide p-1 uppercase w-full hover:bg-illYellow-400 transition ease-in cursor-pointer'
              data-name={props.name}
              data-sku={props.sku}
              data-icon1={props.icon1}
              data-icon2={props.icon2}
              data-cat={props.cat}
              data-type={props.type}
              onClick={handleChangeItem}
            >
              Select
            </p>
          </div>
        </>
      );
    }
  };

  const displayCard = () => {
    let unlockQuery = `U${props.lvl}`;
    // console.log(parseInt(loadoutCon[loadoutCon.loadoutItemKey].SKU), props.sku);

    // if (loadoutCon[loadoutCon.loadoutItemKey].SKU === props.sku) {
    //   console.log(
    //     `=================================================${
    //       loadoutCon[loadoutCon.loadoutItemKey].SKU
    //     } is === ${props.sku}. We are at the top of the function`
    //   );
    // }

    if (unlocks[unlockQuery] === 0 && props.lock === 'Rank') {
      return (
        <div className='max-w-sm bg-gray-800 rounded-sm border border-gray-800 shadow-sm'>
          <p className='text-gray-400 text-left font-bold p-1 text-sm sm:text-sm'>
            {props.name}
          </p>
          <div className='group bg-gray-900'>
            <img
              src={props.icon1}
              alt={`${props.name} icon`}
              title={props.name}
              className='w-24 h-20 mx-auto cursor-not-allowed '
              data-name={props.name}
              data-sku={props.sku}
              data-icon1={props.icon1}
              data-icon2={props.icon2}
              data-cat={props.cat}
              data-type={props.type}
              onMouseOver={changeImg}
              onMouseLeave={resetImg}
            />
          </div>

          <div className='h-8 flex justify-between items-center'>
            <p className='uppercase text-gray-300 text-sm pl-1'>
              Req LVL: {props.lvl}
            </p>
            <p className='bg-illOrange-500 p-1 text-gray-100 uppercase cursor-not-allowed flex justify-center items-center sm:tracking-tight md:tracking-normal lg:tracking-wide'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5 mr-1'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z'
                />
              </svg>
              Locked
            </p>
          </div>
        </div>
      );
    }
    if (unlocks[unlockQuery] === 0 && props.lock === 'Game') {
      if (props.parID1 !== 0 && unlocks[`U${props.parID1}`] === 0) {
        return (
          <div className='max-w-sm bg-gray-800 rounded-sm border border-gray-800 shadow-sm'>
            <p className='text-gray-400 text-left font-bold p-1 text-sm sm:text-sm'>
              {props.name}
            </p>
            <div className='group bg-gray-900'>
              <img
                src={props.icon1}
                alt={`${props.name} icon`}
                title={props.name}
                className='w-24 h-20 mx-auto cursor-not-allowed '
                data-name={props.name}
                data-sku={props.sku}
                data-icon1={props.icon1}
                data-icon2={props.icon2}
                data-cat={props.cat}
                data-type={props.type}
                onMouseOver={changeImg}
                onMouseLeave={resetImg}
              />
            </div>

            <div className='h-8 flex justify-between items-center'>
              <p className='uppercase text-gray-300 text-sm pl-1'>
                Req LVL: {props.parID1}
              </p>
              <p className='bg-illOrange-600 p-1 text-gray-100 uppercase cursor-not-allowed flex justify-center items-center sm:tracking-tight md:tracking-normal lg:tracking-wide'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5 mr-1'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z'
                  />
                </svg>
                Locked
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className='max-w-sm bg-gray-800 rounded-sm border border-gray-800 shadow-sm'>
            {showModal ? (
              <ArmoryLowBalanceModal stateChanger={setShowModal} />
            ) : (
              returnNull()
            )}

            <p className='text-gray-400 text-left font-bold p-1 text-sm'>
              {props.name}
            </p>
            <div className='group bg-gray-900'>
              <img
                src={props.icon1}
                alt={`${props.name} icon`}
                title={props.name}
                className='w-24 h-20 mx-auto cursor-not-allowed'
                data-name={props.name}
                data-sku={props.sku}
                data-icon1={props.icon1}
                data-icon2={props.icon2}
                data-cat={props.cat}
                data-type={props.type}
                onMouseOver={changeImg}
                onMouseLeave={resetImg}
              />
            </div>

            <div className='h-8 flex justify-between items-center'>
              <p className='uppercase text-gray-300 text-sm pl-1'>
                Cost: {props.cost} WB
              </p>
              <p
                href='#'
                className='bg-green-700 text-white py-1 px-4 tracking-wide uppercase cursor-pointer hover:bg-green-600 transition ease-in'
                onClick={unlockItem}
              >
                Buy
              </p>
            </div>
          </div>
        );
      }
    }
    if (
      (unlocks[unlockQuery] === 0 && props.lock === 'Both') ||
      (unlocks[unlockQuery] === 2 && props.lock === 'Both')
    ) {
      return (
        <div className='max-w-sm bg-gray-800 rounded-sm border border-gray-800 shadow-sm'>
          {showModal && <ArmoryLowBalanceModal stateChanger={setShowModal} />}
          <p className='text-gray-400 text-left font-bold p-1 text-sm'>
            {props.name}
          </p>
          <div className='group bg-gray-900'>
            <img
              src={props.icon1}
              alt={`${props.name} icon`}
              title={props.name}
              className='w-24 h-20 mx-auto cursor-not-allowed'
              data-name={props.name}
              data-sku={props.sku}
              data-icon1={props.icon1}
              data-icon2={props.icon2}
              data-cat={props.cat}
              data-type={props.type}
              onMouseOver={changeImg}
              onMouseLeave={resetImg}
            />
          </div>

          {computeBothMode(unlocks[unlockQuery], props.lvl, props.cost)}
        </div>
      );
    }
    if (unlocks[unlockQuery] === 0 && props.lock === 'Shop') {
      return (
        <div className='max-w-sm bg-gray-800 rounded-sm border border-gray-800 shadow-sm'>
          <p className='text-gray-400 text-left font-bold p-1 text-sm'>
            {props.name}
          </p>
          <div className='group bg-gray-900'>
            <img
              src={props.icon1}
              alt={`${props.name} icon`}
              title={props.name}
              className='rounded-t-lg w-24 h-20 mx-auto cursor-not-allowed hover:opacity-80 transition ease-in-out'
              data-name={props.name}
              data-sku={props.sku}
              data-icon1={props.icon1}
              data-icon2={props.icon2}
              data-cat={props.cat}
              data-type={props.type}
              onMouseOver={changeImg}
              onMouseLeave={resetImg}
            />
          </div>

          <div className='h-8 flex justify-between items-center'>
            <a
              href='#'
              className='bg-illOrange-500 text-white tracking-wide p-1 uppercase w-full hover:bg-illOrange-400 transition ease-in'
            >
              View In the Shop
            </a>
          </div>
        </div>
      );
    }
    if (unlocks[unlockQuery] === 1) {
      return (
        <div
          className='max-w-sm bg-gray-800 rounded-sm border border-gray-800 shadow-sm'
          key={props.sku}
        >
          <p className='text-gray-300 text-left font-bold p-1 text-sm'>
            {props.name}
          </p>

          {/* <div className='group bg-gray-900'>
            <img
              src={props.icon1}
              alt={`${props.name} icon`}
              title={props.name}
              className='rounded-t-lg w-24 h-20 mx-auto cursor-pointer hover:opacity-80 transition ease-in-out'
              data-name={props.name}
              data-sku={props.sku}
              data-icon1={props.icon1}
              data-icon2={props.icon2}
              data-cat={props.cat}
              data-type={props.type}
              onClick={handleChangeItem}
              onMouseEnter={changeImg}
              onMouseLeave={resetImg}
            />
          </div> */}

          {checkEquipped()}

          {/* <div className='h-8 flex justify-between items-center'>
            {parseInt(loadoutCon[loadoutCon.loadoutItemKey].SKU) ===
            props.sku ? (
              <p
                className='bg-illYellow-500 text-gray-900 tracking-wide p-1 uppercase w-full hover:bg-illYellow-400 transition ease-in cursor-pointer'
                data-name={props.name}
                data-sku={props.sku}
                data-icon1={props.icon1}
                data-icon2={props.icon2}
                data-cat={props.cat}
                data-type={props.type}
                // onClick={handleChangeItem}
              >
                Equipped
              </p>
            ) : (
              <p
                className='bg-illYellow-500 text-gray-900 tracking-wide p-1 uppercase w-full hover:bg-illYellow-400 transition ease-in cursor-pointer'
                data-name={props.name}
                data-sku={props.sku}
                data-icon1={props.icon1}
                data-icon2={props.icon2}
                data-cat={props.cat}
                data-type={props.type}
                onClick={handleChangeItem}
              >
                Select
              </p>
            )}
          </div> */}
        </div>
      );
      // } else if (unlocks[unlockQuery] > 2) {
    }

    if (parseInt(loadoutCon[loadoutCon.loadoutItemKey].SKU) === props.sku) {
      console.log(
        `=================================================${
          loadoutCon[loadoutCon.loadoutItemKey]
        } is === ${props.sku}`
      );
    } else {
      console.log('something went wrong with unlocks[unlocksQuery]');
      console.log(props, `User's unlockQuery number: ${unlocks[unlockQuery]}`);
      return (
        <div className='flex h-full items-center justify-center'>
          <h2>Something went wrong</h2>
        </div>
      );
    }
  };
  return displayCard();
}
