import React, { useEffect, useState } from 'react';

import ArmoryItemCardLgPrim from './ArmoryItemCardLgPrim';
import ArmoryItemCardLgSec from './ArmoryItemCardLgSec';
import ArmoryItemCardMd from './ArmoryItemCardMd';
import ArmoryItemCardSm from './ArmoryItemCardSm';
import { useLoadout } from '../context/LoadoutContext';
import { useItemList } from '../context/ItemListContext';

export default function ArmoryLoadoutDisplay() {
  const [shouldLoad, setShouldLoad] = useState(false);

  const loadoutCon = useLoadout();
  const itemList = useItemList();

  useEffect(() => {
    console.log(
      `LoadoutCon.Bag check on useEffect in LoadoutDisplay: ----------${loadoutCon.Bag}`
    );

    if (loadoutCon.Bag !== undefined && loadoutCon.Bag !== null) {
      setShouldLoad(true);
      console.log(`LoadoutCon.Bag is defined...... and not null`);
    }
  }, []);

  const isLoading = () => {
    return (
      <h1>
        Sorry, an error occurred. Please refresh the page by pressing "F5", or
        selecting another class.
      </h1>
    );
  };
  const pageLoaded = () => {
    if (shouldLoad) {
      itemList.itemList
        ? console.log('itemList is defined')
        : console.log(`itemList is NOT defined`);
      console.log(loadoutCon);
      return (
        <>
          <ArmoryItemCardLgPrim
            title={'Primary'}
            name={loadoutCon.Prime.uName}
            icon1={loadoutCon.Prime.URL1}
            icon2={loadoutCon.Prime.URL2}
            sku1={loadoutCon.Prime.SKU}
            // cat1={loadoutCon.loadout.Prime.Cat}
            cat1={'Primary'}
            type1={'Primary'}
            loadoutName1={'Prime'}
            // onClick={handleClick}
          />
          <ArmoryItemCardLgSec
            title={'Secondary'}
            name={loadoutCon.Secondary.uName}
            icon1={loadoutCon.Secondary.URL1}
            icon2={loadoutCon.Secondary.URL2}
            sku1={loadoutCon.Secondary.SKU}
            cat1={'Secondary'}
            type1={'Secondary'}
            loadoutName1={'Secondary'}
          />
          <ArmoryItemCardMd
            title1={'Headwear'}
            title2={'Face'}
            name1={loadoutCon.Hat.uName}
            name2={loadoutCon.Vision.uName}
            icon1={loadoutCon.Hat.URL1}
            icon2={loadoutCon.Vision.URL1}
            sku1={loadoutCon.Hat.SKU}
            sku2={loadoutCon.Vision.SKU}
            cat1={'Clothing'}
            type1={'Hat'}
            cat2={'Clothing'}
            type2={'Face'}
            loadoutName1={'Hat'}
            loadoutName2={'Vision'}
          />
          <ArmoryItemCardMd
            title1={'Throwable 1'}
            title2={'Throwable 2'}
            name1={loadoutCon.Throw1.uName}
            name2={loadoutCon.Throw2.uName}
            icon1={loadoutCon.Throw1.URL1}
            icon2={loadoutCon.Throw2.URL1}
            sku1={loadoutCon.Throw1.SKU}
            sku2={loadoutCon.Throw2.SKU}
            cat1={'Throwable'}
            type1={'Throwable'}
            cat2={'Throwable'}
            type2={'Throwable'}
            loadoutName1={'Throw1'}
            loadoutName2={'Throw2'}
          />
          <ArmoryItemCardMd
            title1={'Torso'}
            title2={'Vest'}
            name1={loadoutCon.Top.uName}
            name2={loadoutCon.Vest.uName}
            icon1={loadoutCon.Top.URL1}
            icon2={loadoutCon.Vest.URL1}
            sku1={loadoutCon.Top.SKU}
            sku2={loadoutCon.Vest.SKU}
            cat1={'Clothing'}
            type1={'Top'}
            cat2={'Clothing'}
            type2={'Vest'}
            loadoutName1={'Top'}
            loadoutName2={'Vest'}
          />
          <ArmoryItemCardMd
            title1={'Gadget 1'}
            title2={'Gadget 2'}
            name1={loadoutCon.Gadget1.uName}
            name2={loadoutCon.Gadget2.uName}
            icon1={loadoutCon.Gadget1.URL1}
            icon2={loadoutCon.Gadget2.URL1}
            sku1={loadoutCon.Gadget1.SKU}
            sku2={loadoutCon.Gadget2.SKU}
            cat1={'Gadget'}
            type1={'Gadget1'}
            typeAll1={'Gadget0'}
            cat2={'Gadget'}
            type2={'Gadget2'}
            loadoutName1={'Gadget1'}
            loadoutName2={'Gadget2'}
          />
          <ArmoryItemCardMd
            title1={'Pack'}
            title2={'Legs'}
            name1={loadoutCon.Bag.uName}
            name2={loadoutCon.Bottom.uName}
            icon1={loadoutCon.Bag.URL1}
            icon2={loadoutCon.Bottom.URL1}
            sku1={loadoutCon.Bag.SKU}
            sku2={loadoutCon.Bottom.SKU}
            cat1={'Clothing'}
            type1={'Bag'}
            cat2={'Clothing'}
            type2={'Bottom'}
            loadoutName1={'Bag'}
            loadoutName2={'Bottom'}
          />

          <div className='grid grid-cols-2 gap-1'>
            <ArmoryItemCardSm
              title1={'Melee'}
              name1={loadoutCon.Melee.uName}
              icon1={loadoutCon.Melee.URL1}
              sku1={loadoutCon.Melee.SKU}
              cat1={'Melee'}
              type1={'Melee'}
              loadoutName1={'Melee'}
            />
            {loadoutCon.HasVeh.SKU === 1 && (
              <ArmoryItemCardSm
                title1={'Vehicle'}
                name1={loadoutCon.VehID.uName}
                icon1={loadoutCon.VehID.URL1}
                icon2={loadoutCon.VehID.URL1}
                sku1={loadoutCon.VehID.SKU}
                cat1={'Vehicle'}
                type1={'Vehicle'}
                loadoutName1={'VehID'}
              />
            )}
          </div>
        </>
      );
    } else {
      return isLoading();
    }
  };

  return (
    // <div>
    // <div className='armory_loadout_display flex flex-wrap -mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-1'>
    <div className='armory_loadout_display grid gap-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 col-span-1'>
      {/* {loadoutCon.loadout !== null ? showLoadout() : isLoading()} */}
      {/* {shouldLoad ? pageLoaded() : isLoading()} */}
      {pageLoaded()}
    </div>
  );
}
