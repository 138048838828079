import React, { useEffect, useState } from 'react';
import { useLoadout } from '../context/LoadoutContext';
import { useUser } from '../context/UserContext';

export default function ArmoryClassSelectors() {
  const [classType, setClassType] = useState('inf');
  const user = useUser();
  const loadoutCon = useLoadout();
  useEffect(() => {
    if (
      user.activeLoadout <= 8 ||
      user.activeLoadout === 19 ||
      user.activeLoadout === 20
    ) {
      setClassType('inf');
    } else {
      setClassType('veh');
    }
  }, [user.activeLoadout]);

  const handleChangeClassCat = e => {
    setClassType(e.currentTarget.dataset.type);
    loadoutCon.setWebGearSearch(null);
  };

  const displayClassCat = () => {
    // if (classList === 1) {
    return (
      <>
        <li
          // className={
          //   classType === 'inf'
          //     ? 'font-bold text-illOrange-500 select-none mr-2 p-1 text-lg border-b-2 border-illOrange-500 cursor-pointer transition-all ease-in'
          //     : 'font-bold text-gray-300 select-none mr-2 p-1 text-lg border-b-2 border-transparent cursor-pointer hover:border-illOrange-500 transition-all ease-in'
          // }

          className={`${
            classType === 'inf'
              ? 'text-illOrange-500 border-illOrange-500'
              : 'text-gray-300 border-transparent hover:border-illOrange-500 hover:text-gray-200 '
          } font-bold  select-none mr-2 p-1 border-b-2 cursor-pointer transition-all ease-in uppercase sm:text-sm md:text-lg sm:mr-1 md:mr-1`}
          onClick={handleChangeClassCat}
          data-type='inf'
        >
          Infantry
        </li>
        <li
          // className={
          //   classType === 'veh'
          //     ? 'font-bold text-illOrange-500 select-none mr-2 p-1 text-lg border-b-2 border-illOrange-500 cursor-pointer transition-all ease-in'
          //     : 'font-bold text-gray-300 select-none mr-2 p-1 text-lg border-b-2 border-transparent cursor-pointer  transition-all ease-in'
          // }

          className={`${
            classType === 'veh'
              ? 'text-illOrange-500 border-illOrange-500'
              : 'text-gray-300 border-transparent hover:border-illOrange-500 hover:text-gray-200 '
          } font-bold  select-none mr-2 p-1 border-b-2 cursor-pointer transition-all ease-in uppercase sm:text-sm md:text-lg sm:mr-1 md:mr-1`}
          onClick={handleChangeClassCat}
          data-type='veh'
        >
          Vehicles
        </li>
      </>
    );
  };

  const getLoadoutID = e => {
    const clickedId = e.target.dataset.id;
    user.setUserActiveLoadout(parseInt(clickedId));
    // setActive(clickedId);
  };

  const showClasses = () => {
    if (classType === 'inf') {
      return (
        <>
          {[
            ['Assault', 1],
            ['Medic', 2],
            ['Support', 3],
            ['Anti-Tank', 4],
            ['Logistic', 5],
            ['Recon', 6],
            ['Crewman', 7],
            ['Copilot', 8],
            // ['Specialist', 19],
            // ['Commander', 20],
          ].map(([name, id]) => (
            <li
              onClick={getLoadoutID}
              data-id={id}
              // className={
              //   user.activeLoadout === id
              //     ? 'font-bold text-illYellow-500 select-none mr-3 p-1 text-lg border-b-2 border-illYellow-500 cursor-pointer transition-all ease-in'
              //     : 'font-bold text-gray-300 select-none mr-3 p-1 text-lg border-b-2 border-transparent cursor-pointer hover:border-illYellow-500 hover:text-gray-200 transition-all ease-in'
              // }
              className={`${
                user.activeLoadout === id
                  ? 'text-illYellow-500 border-illYellow-500'
                  : 'text-gray-300 border-transparent hover:border-illYellow-500 hover:text-gray-200 '
              } font-bold select-none p-1 border-b-2 cursor-pointer transition-all ease-in sm:text-sm md:text-base sm:mr-1 md:mr-1`}
              key={id}
            >
              {name}
            </li>
          ))}
        </>
      );
    } else {
      return (
        <>
          {[
            ['Ground Tran', 9],
            ['Light Combat', 10],
            ['IFV', 11],
            ['Tank', 12],
            ['Ground Support', 13],
            ['Boat Tran', 14],
            ['Armed Boat', 15],
            ['Air Tran', 16],
            ['Logistic', 17],
            ['Medevac', 22],
            ['Heli Support', 18],
            ['Plane Support', 21],
          ].map(([name, id]) => (
            <li
              onClick={getLoadoutID}
              data-id={id}
              // className='cursor-pointer bg-gray-700 mr-1 p-1 flex items-center border border-transparent hover:bg-gray-600 transition ease-in-out'
              className={`${
                user.activeLoadout === id
                  ? 'text-illYellow-500 border-illYellow-500'
                  : 'text-gray-300 border-transparent hover:border-illYellow-500 hover:text-gray-200 '
              } font-bold select-none p-1 border-b-2 cursor-pointer transition-all ease-in sm:text-sm md:text-base sm:mr-1 md:mr-1`}
              key={id}
            >
              {name}
            </li>
          ))}
        </>
      );
    }
  };

  return (
    <header className='border-b border-gray-800'>
      <ul className='flex justify-start border-b border-gray-800'>
        {displayClassCat()}
      </ul>
      <ul className='flex flex-wrap justify-start'>{showClasses()}</ul>
    </header>
  );
}
