import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

// Route imports
import Armory from './routes/armory';
import Login from './routes/login';
import Home from './routes/home';
import PWM from './routes/pwm';
import Stats from './routes/stats';
import Unlocks from './routes/unlocks';
import Layout from './components/Layout';
import RequireAuth from './context/RequireAuth';
import AuthContextProvider from './context/AuthContext';
import UserContextProvider from './context/UserContext';
import ItemListContextProvider from './context/ItemListContext';
import LoadoutContextProvider from './context/LoadoutContext';
import UnlocksContextProvider from './context/UnlocksContext';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesProvider>
        <AuthContextProvider>
          <UserContextProvider>
            <ItemListContextProvider>
              <UnlocksContextProvider>
                <LoadoutContextProvider>
                  <Routes>
                    <Route element={<Layout />}>
                      {/* <Route path='/' element={<App />}> */}
                      <Route path='/' element={<Home />} />
                      <Route
                        path='armory'
                        element={
                          <RequireAuth>
                            <Armory />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path='stats'
                        element={
                          <RequireAuth>
                            <Stats />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path='unlocks'
                        element={
                          <RequireAuth>
                            <Unlocks />
                          </RequireAuth>
                        }
                      />
                      <Route path='login' element={<Login />} />
                      <Route path='pwm' element={<PWM />} />
                    </Route>
                  </Routes>
                </LoadoutContextProvider>
              </UnlocksContextProvider>
            </ItemListContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
        {/* <App /> */}
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
