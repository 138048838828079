import Axios from 'axios';
import { parseJwt } from './jwtDecoder';
// import { useUser } from '../context/UserContext';

// Decode JWT
// const parseJwt = token => {
//   try {
//     return JSON.parse(atob(token.split('.')[1]));
//   } catch (e) {
//     return null;
//   }
// };

// const user = useUser();

export const getApiUser = () => {
  Axios.get(`${process.env.REACT_APP_API_URL}/validate`, {
    withCredentials: true,
  })
    .then(res => {
      if (!res.data[1]) {
        console.log('Session isn"t valid');
      } else {
        console.log('user is logged in with cookies');
        let userJWT = parseJwt(res.data[0]);
        return userJWT;
      }
    })
    .catch(err => console.log(err));
};

export const getUserServerInfo = steamID => {
  Axios.get(`${process.env.REACT_APP_API_URL}/userstats`, {
    params: {
      ID: steamID,
    },
  })
    .then(res => {
      console.log(res.data[0]);
    })
    .catch(err => console.log(err));
};

export const decodeActiveLoadout = loadoutID => {
  let table;
  switch (loadoutID) {
    case 1:
      table = 'linf_assault';
      break;
    case 2:
      table = 'linf_medic';
      break;
    case 3:
      table = 'linf_support';
      break;
    case 4:
      table = 'linf_at';
      break;
    case 5:
      table = 'linf_logi';
      break;
    case 6:
      table = 'linf_recon';
      break;
    case 7:
      table = 'linf_crew';
      break;
    case 8:
      table = 'linf_pilot';
      break;
    case 9:
      table = 'lveh_tran_ground';
      break;
    case 10:
      table = 'lveh_lt_combat';
      break;
    case 11:
      table = 'lveh_ifv';
      break;
    case 12:
      table = 'lveh_tank';
      break;
    case 13:
      table = 'lveh_support';
      break;
    case 14:
      table = 'lveh_tran_boat';
      break;
    case 15:
      table = 'lveh_armed_boat';
      break;
    case 16:
      table = 'lveh_tran_air';
      break;
    case 17:
      table = 'lveh_logi';
      break;
    case 18:
      table = 'lveh_air_sup';
      break;
    case 19:
      table = 'linf_special';
      break;
    case 20:
      table = 'linf_command';
      break;
    case 21:
      table = 'lveh_plane_sup';
      break;
    case 22:
      table = 'lveh_medical';
      break;
    default:
      console.log('Oops something went wrong in the Switch');
  }
  return table;
};

export const encodeLoadoutID = loadoutID => {
  let encodedLoadout;
  switch (parseInt(loadoutID)) {
    case 1:
      encodedLoadout = 'Assault';
      break;
    case 2:
      encodedLoadout = 'Medic';
      break;
    case 3:
      encodedLoadout = 'Support';
      break;
    case 4:
      encodedLoadout = 'Anti-Tank';
      break;
    case 5:
      encodedLoadout = 'Logistics';
      break;
    case 6:
      encodedLoadout = 'Recon';
      break;
    case 7:
      encodedLoadout = 'Crewman';
      break;
    case 8:
      encodedLoadout = 'Copilot';
      break;
    case 9:
      encodedLoadout = 'Ground Transport';
      break;
    case 10:
      encodedLoadout = 'Light Combat';
      break;
    case 11:
      encodedLoadout = 'IFV';
      break;
    case 12:
      encodedLoadout = 'Tank';
      break;
    case 13:
      encodedLoadout = 'Ground Support';
      break;
    case 14:
      encodedLoadout = 'Boat Transport';
      break;
    case 15:
      encodedLoadout = 'Boat Armed';
      break;
    case 16:
      encodedLoadout = 'Air Transport';
      break;
    case 17:
      encodedLoadout = 'Logistic Vehicle';
      break;
    case 18:
      encodedLoadout = 'Heli Support';
      break;
    case 19:
      encodedLoadout = 'Specialist';
      break;
    case 20:
      encodedLoadout = 'Commander';
      break;
    case 21:
      encodedLoadout = 'Plane Support';
      break;
    case 22:
      encodedLoadout = 'Medevac';
      break;
    default:
      console.log('Oops something went wrong in the Switch');
  }
  return encodedLoadout;
};

export const getUserUnlocks = steamID => {
  Axios.get(`${process.env.REACT_APP_API_URL}/userstats`, {
    params: {
      ID: steamID,
    },
  })
    .then(res => {
      console.log(res.data[0]);
    })
    .catch(err => console.log(err));
};

export const loadoutIDConversion = activeLoadout => {
  let className;

  switch (activeLoadout) {
    case 1:
      className = 'C_Assault';
      break;
    case 2:
      className = 'C_Medic';
      break;
    case 3:
      className = 'C_Sup';
      break;
    case 4:
      className = 'C_AT';
      break;
    case 5:
      className = 'C_Logi';
      break;
    case 6:
      className = 'C_Recon';
      break;
    case 7:
      className = 'C_Crew';
      break;
    case 8:
      className = 'C_Pilot';
      break;
    case 9:
      className = 'C_Crew';
      break;
    case 10:
      className = 'C_Crew';
      break;
    case 11:
      className = 'C_Crew';
      break;
    case 12:
      className = 'C_Crew';
      break;
    case 13:
      className = 'C_Crew';
      break;
    case 14:
      className = 'C_Crew';
      break;
    case 15:
      className = 'C_Crew';
      break;
    case 16:
      className = 'C_Pilot';
      break;
    case 17:
      className = 'C_Logi';
      break;
    case 18:
      className = 'C_Pilot';
      break;

    // Change below to the new specialist and commander columns in the DB
    case 19:
      className = 'C_Jug';
      break;
    case 20:
      className = 'C_Spec';
      break;
    case 21:
      className = 'C_Pilot';
      break;
    case 22:
      className = 'C_Medic';
      break;
    default:
      console.log('Oops something went wrong in the Switch');
  }
  return className;
};

// export const updateLoadout = (steamID, activeLoadout, slot, loadout) => {
//   // let resultStatus;
//   console.table(steamID, activeLoadout, slot);
//   console.log(loadout);
//   Axios.put(`${process.env.REACT_APP_API_URL}/updateloadout`, {
//     params: {
//       ID: steamID,
//       Table: activeLoadout,
//       Slot: slot,
//       Loadout: loadout,
//     },
//   })
//     .then(res => {
//       console.log(res);
//       if (res.data.affectedRows > 0) {
//         console.log(res.data.affectedRows);
//         return 'success';
//         // console.log(`Inside res.data.affectedRows > 0 ${resultStatus}`);
//         // return resultStatus;
//       } else {
//         console.log(res.data);
//         return 'failed';
//         // return resultStatus;
//       }
//     })
//     .catch(err => console.log(err));
//   // return 'hellow';
// };

export const updateUnlockList = (steamID, unlockLvl, value) => {
  Axios.put(`${process.env.REACT_APP_API_URL}/updateunlocks`, {
    params: {
      ID: steamID,
      UnlockLvl: unlockLvl,
      Value: value,
    },
  })
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
};

export const updateUserCredits = (steamID, value) => {
  Axios.put(`${process.env.REACT_APP_API_URL}/updateusercredits`, {
    params: {
      ID: steamID,
      Credit: value,
    },
  })
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
};

export const getFullLeaderBoard = async () => {
  let res = await Axios.get(`${process.env.REACT_APP_API_URL}/getleaderboard`);
  let data = await res.data;
  console.log(data);
};
