import { React, useState } from 'react';

import Axios from 'axios';
import { BsDiscord, BsBagHeart, BsYoutube } from 'react-icons/bs';
import { FaStore } from 'react-icons/fa';

// Components
import { AuthContext } from '../context/AuthContext';

// import { CTAButtonMD } from '../components/ctaButtons/CTAButtonMD';

// Will be turned into the illmatikgaming home page for the default site for illmatikgaming.com

const Home = () => {
  return (
    <div className='text-center '>
      <img
        src='/DeadAwakening-02.png'
        alt='Dead Awakening Header image'
        className='md:w-1/2 sm:w-full m-auto'
      />
      <div className='serverIP my-10 flex md:flex-row md:gap-4 lg:gap-6 sm:gap-2 justify-center sm:flex-col'>
        <h2 className='text-lg'>
          IP:
          <span className='text-2xl text-illOrange-400 p-2'>
            144.48.106.179
          </span>
        </h2>
        <h2 className='text-lg'>
          PORT:
          <span className='text-2xl text-illYellow-400 p-2'>27032</span>
        </h2>
      </div>
      <div className=' grid md:grid-cols-2 gap-6 sm:grid-rows-1 mx-auto container'>
        <div className='flex flex-col items-center justify-between'>
          <h2 className='text-2xl text-gray-300'>Visit our Shop</h2>
          <p className='px-4 my-6'>
            Want to check out some sick deals? Come swing by our store and see
            what you like.
          </p>
          <a
            href='https://shop.illmatik.net/'
            className='inline-block text-7xl text-softWhite hover:text-illOrange hover:animate-wiggle ease-in duration-200 '
            title='Visit the shop'
          >
            <FaStore />
          </a>
        </div>

        {/* <div className='flex flex-col items-center justify-between sm:'>
          <h2 className='text-2xl text-gray-300'>Watch our trailer</h2>
          <p className='px-4 my-6'>
            Want to see what it's like? Watch our trailer, better yet join the
            server and try it yourself!
          </p>
          <a
            href='https://www.youtube.com/watch?v=uP8YxJq3ilg'
            className='inline-block text-7xl text-softWhite hover:text-illOrange hover:animate-wiggle ease-in duration-200'
            title='Watch the trailer'
          >
            <BsYoutube />
          </a>
        </div> */}

        <div className='flex flex-col items-center justify-between'>
          <h2 className='text-2xl text-gray-300'>Join our Discord</h2>
          <p className='px-4 my-6'>
            Learn more about our Server from our Discord. Stop by, stay
            awhile....
          </p>

          <a
            href='https://discord.com/invite/AzPhn8WaWb'
            className='inline-block text-7xl text-softWhite hover:text-illOrange hover:animate-wiggle ease-in duration-200'
            title='Join the Discord'
          >
            <BsDiscord />
          </a>
          {/* <a
            className='text-illWhite-300 bg-illOrange p-3 rounded inline-block hover:bg-illYellow-500 hover:text-illWhite-100 ease-in duration-200 uppercase'
            href='https://discord.com/invite/AzPhn8WaWb'
            title='Click me to join'
          >
            I'm ready, Let me in
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
