import React from 'react';
import { useLoadout } from '../context/LoadoutContext';

export default function ArmoryItemCardSm(props) {
  const loadoutCon = useLoadout();
  const handleClick = e => {
    const clickedItemCat = e.target.dataset.cat;
    const clickedItemType = e.target.dataset.type;
    const clickedLoadoutKey = e.target.dataset.lkey;

    loadoutCon.setWebGearSearch(clickedItemCat, clickedItemType);
    loadoutCon.setLoadoutItemKey(clickedLoadoutKey);
  };

  return (
    // <div className='armory_card_md w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-1 md:px-1 md:w-1/2 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2'>
    <div className='armory_card_md w-full'>
      <div className='armory_card_md_main border border-solid border-gray-800'>
        <div className='armory_card_title bg-gray-800 uppercase text-gray-200 text-left flex justify-between items-center p-1'>
          <p className='font-bold tracking-wide'> {props.title1}</p>
        </div>
        {/* <div className='armory-card-md-imgs flex justify-between'> */}
        <div className='armory-card-md-imgs grid grid-cols-1 gap-0'>
          <img
            src={`${props.icon1}`}
            alt={`${props.title1} icon`}
            title={props.name1}
            className='h-16 mx-auto hover:opacity-80 transition ease-in cursor-pointer'
            data-cat={props.cat1}
            data-type={props.type1}
            data-lkey={props.loadoutName1}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}
