import React, { useState, useEffect } from 'react';
import { useLoadout } from '../context/LoadoutContext';
import { useUser } from '../context/UserContext';
import { loadoutIDConversion } from '../services/armoryCalls';
import ArmoryGearCard from './ArmoryGearCard';
import { useItemList } from '../context/ItemListContext';

export default function ArmoryGearSelection() {
  const loadoutCon = useLoadout();
  const itemList = useItemList();
  const user = useUser();
  const [activeClassName, setActiveClassName] = useState(null);

  useEffect(() => {
    let className = loadoutIDConversion(user.activeLoadout);
    setActiveClassName(className);
  }, [user.activeLoadout]);

  const returnNull = () => {
    return null;
  };

  const writeGearCard = item => {
    return (
      <ArmoryGearCard
        name={item.uName}
        icon1={item.URL1}
        icon2={item.URL2}
        sku={item.SKU}
        cost={item.Cost}
        lock={item.Lock}
        lvl={item.Lvl}
        shop={item.ShopURL}
        cat={item.Cat}
        type={item.Type}
        key={item.SKU}
        parID1={item.ParID1}
      />
    );
  };

  return (
    <section className='armory_loadout_item_selection border border-gray-800 shadow-sm sm:col-span-1 md:col-span-1 lg:col-span-1'>
      <h2 className='uppercase text-lg font-bold text-gray-200'>
        {loadoutCon.webGearSearch.type !== null
          ? loadoutCon.webGearSearch.type
          : 'Choose an Item'}
      </h2>
      <div className='grid gap-1 sm:grid-cols-2 md:grid-cols-2 md:gap-1 lg:grid-cols-2 p-1 max-h-120 overflow-y-scroll'>
        {/* {loadoutCon.webGearSearch.cat === null
          ? returnNull() */}
        {loadoutCon.webGearSearch.cat !== null &&
          // ? returnNull()
          // :
          // itemList.itemList.map(item => {
          itemList.itemList
            .sort((a, b) => {
              return a.Lvl - b.Lvl;
            })
            .map(item => {
              // Checks if primary or secondary to use .cat instead of .type
              if (
                (loadoutCon.webGearSearch.cat === 'Primary' &&
                  activeClassName !== 'C_Jug') ||
                loadoutCon.webGearSearch.cat === 'Secondary'
              ) {
                if (
                  item.Cat === loadoutCon.webGearSearch.cat ||
                  item.Cat === 'All'
                ) {
                  if (item['C_All'] === 1 || item[activeClassName] === 1) {
                    return writeGearCard(item);
                  }
                }
              }

              // Gets universal gadgets in either gadget 1 or 2 slots

              if (loadoutCon.webGearSearch.cat === 'Gadget') {
                if (
                  item.Cat === 'All' ||
                  item.Type === 'Gadget0' ||
                  loadoutCon.webGearSearch.type === item.Type
                ) {
                  if (item['C_All'] === 1 || item[activeClassName] === 1) {
                    return writeGearCard(item);
                  }
                }
              }

              // Template for Pilots Class
              if (activeClassName === 'C_Pilot') {
                // Ones where There should only be Jug exclusive an no empty
                if (
                  loadoutCon.webGearSearch.cat === 'Clothing' &&
                  (loadoutCon.webGearSearch.type !== 'Vest' ||
                    loadoutCon.webGearSearch.type !== 'Bag')
                ) {
                  if (
                    item.Type === loadoutCon.webGearSearch.type &&
                    item[activeClassName] === 1
                  ) {
                    return writeGearCard(item);
                  }
                } else {
                  if (
                    item.Cat === 'All' ||
                    item.Type === loadoutCon.webGearSearch.type
                  ) {
                    if (item[activeClassName] === 1) {
                      return writeGearCard(item);
                    }
                  }
                }
              }

              // ---------------------------------------------

              // Template for Jug Class (Specialist)
              if (activeClassName === 'C_Jug') {
                // Ones where There should only be Jug exclusive an no empty
                if (
                  loadoutCon.webGearSearch.type === 'Hat' ||
                  loadoutCon.webGearSearch.type === 'Top' ||
                  loadoutCon.webGearSearch.type === 'Bottom' ||
                  loadoutCon.webGearSearch.type === 'Vest' ||
                  loadoutCon.webGearSearch.type === 'Bag'
                ) {
                  if (item.Type === loadoutCon.webGearSearch.type)
                    if (item[activeClassName] === 1) {
                      return writeGearCard(item);
                    }
                } else {
                  // Ones where There should only be Jug exclusive plus an empty
                  if (loadoutCon.webGearSearch.cat === 'Primary') {
                    if (item.Cat === loadoutCon.webGearSearch.cat)
                      if (item[activeClassName] === 1) {
                        return writeGearCard(item);
                      }
                  }
                  if (
                    item.Cat === 'All' ||
                    item.Type === loadoutCon.webGearSearch.type
                  ) {
                    if (item['C_All'] || item[activeClassName] === 1) {
                      return writeGearCard(item);
                    }
                  }
                }
                // }
                //
              }
              // ===============================
              // Restricts Tops and Bottoms from having an empty item for all non specified unique classes
              if (
                loadoutCon.webGearSearch.type === 'Top' ||
                loadoutCon.webGearSearch.type === 'Bottom' ||
                // Controls the vehicle empty card for some reason. Had to put it in the clothing list
                loadoutCon.webGearSearch.cat === 'Vehicle'
              ) {
                if (
                  loadoutCon.webGearSearch.cat === item.Cat &&
                  loadoutCon.webGearSearch.type === item.Type
                ) {
                  if (
                    item['C_All'] === 1 ||
                    (item[activeClassName] === 1 && item.uName !== 'Empty')
                  ) {
                    return writeGearCard(item);
                  }
                }

                // Otherwise show the "Empty" Card
              } else {
                if (
                  item.Cat === 'All' ||
                  (loadoutCon.webGearSearch.cat === item.Cat &&
                    loadoutCon.webGearSearch.type === item.Type)
                ) {
                  if (item['C_All'] === 1 || item[activeClassName] === 1) {
                    return writeGearCard(item);
                  }
                }
              }
              return null;
            })}
        {loadoutCon.webGearSearch.cat === 'Vehicle' &&
          // itemList.vehicleList.map(vehicle => {

          // itemList.vehicleList.map(vehicle => {
          //   // if (loadoutCon.webGearSearch.cat === 'Vehicle') {
          //   if (user.activeLoadout === parseInt(vehicle.Class)) {
          //     return writeGearCard(vehicle);
          //   }
          //   // }
          //   return null;
          // })}

          itemList.vehicleList
            .sort((a, b) => {
              return a.Lvl - b.Lvl;
            })
            .map(vehicle => {
              // if (loadoutCon.webGearSearch.cat === 'Vehicle') {
              if (user.activeLoadout === parseInt(vehicle.Class)) {
                return writeGearCard(vehicle);
              }
              // }
              return null;
            })}
      </div>
    </section>
  );
}
