import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import { useUser } from '../context/UserContext';
// import { ChevronDownIcon } from '@heroicons/react/solid';

export default function ArmoryNavbarDropdown() {
  const user = useUser();

  const handleLogout = () => {
    const logoutUser = async () => {
      let res = await Axios.get(`${process.env.REACT_APP_API_URL}/logout`);
      let data = await res.data;
      // console.log('Logged user out.... ', data);
    };
    console.log('handleLogout ran');

    logoutUser();
  };

  return (
    // <Menu>
    //   <Menu.Button>More</Menu.Button>
    //   <Menu.Items>
    //     <Menu.Item>
    //       {({ active }) => (
    //         <a
    //           className={`${active && 'bg-blue-500'}`}
    //           href='/account-settings'
    //         >
    //           Account settings
    //         </a>
    //       )}
    //     </Menu.Item>
    //     <Menu.Item>
    //       {({ active }) => (
    //         <a
    //           className={`${active && 'bg-blue-500'}`}
    //           href='/account-settings'
    //         >
    //           Documentation
    //         </a>
    //       )}
    //     </Menu.Item>
    //     <Menu.Item disabled>
    //       <span className='opacity-75'>Invite a friend (coming soon!)</span>
    //     </Menu.Item>
    //   </Menu.Items>
    // </Menu>
    <div className=''>
      {/* <div className='w-56 text-right fixed top-16'> */}
      <Menu as='div' className='relative text-left'>
        <div className='flex items-center'>
          {/* <Menu.Button className='inline-flex justify-center w-full px-2 py-1 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'> */}
          <Menu.Button className='flex items-center hover:shadow-lg'>
            <img
              src={user.steamAvatar}
              alt={`${user.steamUsername}'s Steam profile Icon`}
              className='rounded-full h-8'
            />
            <p className='font-bold tracking-wide ml-1 text-gray-200 hover:text-gray-100 transition ease-in'>
              {user.steamUsername}
            </p>
            {/* <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M19 9l-7 7-7-7'
              />
            </svg> */}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 w-56 mt-2 origin-top-right bg-gray-900 divide-y divide-gray-800 rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='px-1 py-1 '>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? 'bg-illOrange-500 text-gray-200'
                        : 'text-gray-200'
                    } group flex rounded-sm items-center w-full px-2 py-2 text-sm`}
                    onClick={handleLogout}
                  >
                    {active ? (
                      <LogoutActiveIcon
                        className='w-5 h-5 mr-2'
                        aria-hidden='true'
                      />
                    ) : (
                      <LogoutInactiveIcon
                        className='w-5 h-5 mr-2'
                        aria-hidden='true'
                      />
                    )}
                    Logout
                  </button>
                )}
              </Menu.Item>
              {/* <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <DuplicateActiveIcon
                        className='w-5 h-5 mr-2'
                        aria-hidden='true'
                      />
                    ) : (
                      <DuplicateInactiveIcon
                        className='w-5 h-5 mr-2'
                        aria-hidden='true'
                      />
                    )}
                    Duplicate
                  </button>
                )}
              </Menu.Item> */}
            </div>
            {/* <div className='px-1 py-1'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <ArchiveActiveIcon
                        className='w-5 h-5 mr-2'
                        aria-hidden='true'
                      />
                    ) : (
                      <ArchiveInactiveIcon
                        className='w-5 h-5 mr-2'
                        aria-hidden='true'
                      />
                    )}
                    Archive
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <MoveActiveIcon
                        className='w-5 h-5 mr-2'
                        aria-hidden='true'
                      />
                    ) : (
                      <MoveInactiveIcon
                        className='w-5 h-5 mr-2'
                        aria-hidden='true'
                      />
                    )}
                    Move
                  </button>
                )}
              </Menu.Item>
            </div> */}
            {/* <div className='px-1 py-1'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <DeleteActiveIcon
                        className='w-5 h-5 mr-2 text-violet-400'
                        aria-hidden='true'
                      />
                    ) : (
                      <DeleteInactiveIcon
                        className='w-5 h-5 mr-2 text-violet-400'
                        aria-hidden='true'
                      />
                    )}
                    Delete
                  </button>
                )}
              </Menu.Item>
            </div> */}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

function LogoutInactiveIcon(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
      />
    </svg>
  );
}

function LogoutActiveIcon(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
      />
    </svg>
  );
}

// Old Default Example Dropdown
// {/* <div className=''>
//       {/* <div className='w-56 text-right fixed top-16'> */}
//       <Menu as='div' className='relative inline-block text-left'>
//         <div>
//           <Menu.Button className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
//             Options
//             <img
//               src={user.steamAvatar}
//               alt={`${user.steamUsername} Steam's profile Icon`}
//               className='rounded-full h-7'
//             />
//             <svg
//               xmlns='http://www.w3.org/2000/svg'
//               className='w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100'
//               fill='none'
//               viewBox='0 0 24 24'
//               stroke='currentColor'
//             >
//               <path
//                 strokeLinecap='round'
//                 strokeLinejoin='round'
//                 strokeWidth={2}
//                 d='M19 9l-7 7-7-7'
//               />
//             </svg>
//           </Menu.Button>
//         </div>
//         <Transition
//           as={Fragment}
//           enter='transition ease-out duration-100'
//           enterFrom='transform opacity-0 scale-95'
//           enterTo='transform opacity-100 scale-100'
//           leave='transition ease-in duration-75'
//           leaveFrom='transform opacity-100 scale-100'
//           leaveTo='transform opacity-0 scale-95'
//         >
//           <Menu.Items className='absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
//             <div className='px-1 py-1 '>
//               <Menu.Item>
//                 {({ active }) => (
//                   <button
//                     className={`${
//                       active ? 'bg-violet-500 text-white' : 'text-gray-900'
//                     } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
//                   >
//                     {active ? (
//                       <EditActiveIcon
//                         className='w-5 h-5 mr-2'
//                         aria-hidden='true'
//                       />
//                     ) : (
//                       <EditInactiveIcon
//                         className='w-5 h-5 mr-2'
//                         aria-hidden='true'
//                       />
//                     )}
//                     Edit
//                   </button>
//                 )}
//               </Menu.Item>
//               <Menu.Item>
//                 {({ active }) => (
//                   <button
//                     className={`${
//                       active ? 'bg-violet-500 text-white' : 'text-gray-900'
//                     } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
//                   >
//                     {active ? (
//                       <DuplicateActiveIcon
//                         className='w-5 h-5 mr-2'
//                         aria-hidden='true'
//                       />
//                     ) : (
//                       <DuplicateInactiveIcon
//                         className='w-5 h-5 mr-2'
//                         aria-hidden='true'
//                       />
//                     )}
//                     Duplicate
//                   </button>
//                 )}
//               </Menu.Item>
//             </div>
//             <div className='px-1 py-1'>
//               <Menu.Item>
//                 {({ active }) => (
//                   <button
//                     className={`${
//                       active ? 'bg-violet-500 text-white' : 'text-gray-900'
//                     } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
//                   >
//                     {active ? (
//                       <ArchiveActiveIcon
//                         className='w-5 h-5 mr-2'
//                         aria-hidden='true'
//                       />
//                     ) : (
//                       <ArchiveInactiveIcon
//                         className='w-5 h-5 mr-2'
//                         aria-hidden='true'
//                       />
//                     )}
//                     Archive
//                   </button>
//                 )}
//               </Menu.Item>
//               <Menu.Item>
//                 {({ active }) => (
//                   <button
//                     className={`${
//                       active ? 'bg-violet-500 text-white' : 'text-gray-900'
//                     } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
//                   >
//                     {active ? (
//                       <MoveActiveIcon
//                         className='w-5 h-5 mr-2'
//                         aria-hidden='true'
//                       />
//                     ) : (
//                       <MoveInactiveIcon
//                         className='w-5 h-5 mr-2'
//                         aria-hidden='true'
//                       />
//                     )}
//                     Move
//                   </button>
//                 )}
//               </Menu.Item>
//             </div>
//             <div className='px-1 py-1'>
//               <Menu.Item>
//                 {({ active }) => (
//                   <button
//                     className={`${
//                       active ? 'bg-violet-500 text-white' : 'text-gray-900'
//                     } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
//                   >
//                     {active ? (
//                       <DeleteActiveIcon
//                         className='w-5 h-5 mr-2 text-violet-400'
//                         aria-hidden='true'
//                       />
//                     ) : (
//                       <DeleteInactiveIcon
//                         className='w-5 h-5 mr-2 text-violet-400'
//                         aria-hidden='true'
//                       />
//                     )}
//                     Delete
//                   </button>
//                 )}
//               </Menu.Item>
//             </div>
//           </Menu.Items>
//         </Transition>
//       </Menu>
//     </div> */}
