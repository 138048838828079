import React from 'react';

export default function ArmoryUserNotFound() {
  return (
    <div className=''>
      <div className='bg-gray-800 p-4 text-lg text-gray-200 w-1/2 mx-auto shadow-md'>
        <h2 className='text-illOrange-500 text-4xl font-bold py-2'>
          User Not Found
        </h2>
        <p>
          You must play on our server before using the Armory. Please log on to
          the server and try again.
        </p>
        <p>If this error persists please contact support via our Discord.</p>
      </div>
    </div>
  );
}
