import React from 'react';
import { useLoadout } from '../context/LoadoutContext';

export default function ArmoryCardPrimAttch(props) {
  const loadoutCon = useLoadout();

  const handleClick = e => {
    const clickedItemCat = e.target.dataset.cat;
    const clickedItemType = e.target.dataset.type;
    const clickedLoadoutKey = e.target.dataset.lkey;

    loadoutCon.setWebGearSearch(clickedItemCat, clickedItemType);
    loadoutCon.setLoadoutItemKey(clickedLoadoutKey);
  };
  return (
    <div
      // className='border border-t-0 border-r-0 border-gray-800'
      className=''
      key={props.key1}
    >
      <img
        src={props.URL1}
        alt={`${props.name} icon`}
        title={props.name}
        className='h-16 mx-auto hover:opacity-80 transition ease-in cursor-pointer'
        onClick={handleClick}
        data-cat={props.cat1}
        data-type={props.type1}
        data-lkey={props.loadoutName1}
      />
    </div>
  );
}
