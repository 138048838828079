import React, { useState } from 'react';
import Axios from 'axios';
import { decodeActiveLoadout } from '../services/armoryCalls';
import { useUser } from '../context/UserContext';
import { useLoadout } from '../context/LoadoutContext';

import ArmoryLoadoutModal from './ArmoryLoadoutModal';

export default function ArmoryLoadoutUpdateBtn() {
  const user = useUser();
  const loadoutCon = useLoadout();

  const [showLoadoutModal, setShowSucLoadoutModal] = useState(false);
  const [modalDet, setModalDet] = useState({
    title: '',
    msg: '',
    color: '',
    btnText: '',
  });

  const updateLoadout = (steamID, activeLoadout, slot, loadout) => {
    // console.table(steamID, activeLoadout, slot);
    // console.log(loadout);
    Axios.put(`${process.env.REACT_APP_API_URL}/updateloadout`, {
      params: {
        ID: steamID,
        Table: activeLoadout,
        Slot: slot,
        Loadout: loadout,
      },
    })
      .then(res => {
        // console.log(res);
        if (res.data.affectedRows > 0) {
          setModalDet({
            title: 'Your Loadout Has Been Saved',
            msg: "You're all set to get back into the game. Remember to refresh your page once in the server to view the changes to your loadout.",
            color: 'green-500',
            btnText: 'Close',
          });

          setShowSucLoadoutModal(true);
        } else {
          setModalDet({
            title: 'Oops... Something Went Wrong',
            msg: 'Your loadout was not saved. Please try again, refresh your page, or contact support if this issues continues',
            color: 'red-500',
            btnText: 'Understood',
          });
          setShowSucLoadoutModal(true);
        }
      })
      .catch(err => console.log(err));
  };

  const handleSubmit = () => {
    let tableName = decodeActiveLoadout(user.activeLoadout);
    // console.log(tableName);

    let loadoutObj = {
      Bag: parseInt(loadoutCon.Bag.SKU),
      Bottom: parseInt(loadoutCon.Bottom.SKU),
      ClassID: parseInt(loadoutCon.ClassID.SKU),
      Gadget1: parseInt(loadoutCon.Gadget1.SKU),
      Gadget2: parseInt(loadoutCon.Gadget2.SKU),
      HasVeh: parseInt(loadoutCon.HasVeh.SKU),
      Hat: parseInt(loadoutCon.Hat.SKU),
      Melee: parseInt(loadoutCon.Melee.SKU),
      P_Barrel: parseInt(loadoutCon.P_Barrel.SKU),
      P_Grip: parseInt(loadoutCon.P_Grip.SKU),
      P_Sight: parseInt(loadoutCon.P_Sight.SKU),
      P_Tactical: parseInt(loadoutCon.P_Tactical.SKU),
      Prime: parseInt(loadoutCon.Prime.SKU),
      S_Barrel: parseInt(loadoutCon.S_Barrel.SKU),
      S_Sight: parseInt(loadoutCon.S_Sight.SKU),
      Secondary: parseInt(loadoutCon.Secondary.SKU),
      SlotID: parseInt(loadoutCon.SlotID.SKU),
      Throw1: parseInt(loadoutCon.Throw1.SKU),
      Throw2: parseInt(loadoutCon.Throw2.SKU),
      Top: parseInt(loadoutCon.Top.SKU),
      VehID: parseInt(loadoutCon.VehID.SKU),
      Vest: parseInt(loadoutCon.Vest.SKU),
      Vision: parseInt(loadoutCon.Vision.SKU),
    };

    // steamID, activeLoadout, slot, loadout
    updateLoadout(user.steamID, tableName, user.webCurrentSlot, loadoutObj);
  };
  return (
    <>
      <button
        onClick={handleSubmit}
        className='bg-gradient-to-br from-illOrange-500 to-illOrange-600 text-gray-100 text-xl font-bold tracking-wider px-12 mt-6 py-2 shadow-sm shadow-gray-800 hover:from-illOrange-400 hover:to-illOrange-500 transition ease-in'
      >
        Save Loadout
      </button>
      {showLoadoutModal ? (
        <ArmoryLoadoutModal
          stateChanger={setShowSucLoadoutModal}
          title={modalDet.title}
          msg={modalDet.msg}
          color={modalDet.color}
          btnText={modalDet.btnText}
        />
      ) : null}
    </>
  );
}
