import React, { useEffect } from 'react';

import { useUser } from '../context/UserContext';
import { useItemList } from '../context/ItemListContext';
import { useLoadout } from '../context/LoadoutContext';
import { useUnlocks } from '../context/UnlocksContext';

// Components
import ArmoryNavbar from '../components/ArmoryNavbar';
import UnlockItemCard from '../components/unlocksComponents/UnlockItemCard';
import UnlockLayout from '../components/unlocksComponents/UnlockLayout';

const Unlocks = () => {
  const user = useUser();
  const itemList = useItemList();
  const loadoutCon = useLoadout();
  const userUnlocks = useUnlocks();

  const sortItemList = arr => {
    const result = arr.filter(item => item.Lvl <= 55);

    result.sort((a, b) => a[0] - b[0]);

    // console.log(result);
    // console.log(result);

    return result.map(item => {
      <UnlockItemCard item={item} />;
      //   <div className='flex '>
      //     <h1 className='mr-2'>{item.Lvl}</h1>
      //     {item.uName}
      //   </div>;
    });
  };

  //   useEffect(() => {
  //     sortItemList(itemList.itemList);
  //   }, []);

  return (
    <div className='flex flex-col min-h-screen'>
      <ArmoryNavbar />
      <UnlockLayout />
      <h2 className='text-2xl'>Rank Unlocks</h2>
      {/* <UnlockItemCard item={'Name'} lvl={'55'} /> */}
      {/* {itemList.itemList.map(item => { */}

      {/* {itemList.itemList
        .filter(item => item.Lvl <= 55)
        .map(item => {
          return (
            item.uName !== 'Empty' && <UnlockItemCard item={item} />
            //   <div className='flex '>
            //     <h1 className='mr-2'>{item.Lvl}</h1>
            //     {item.uName}
            //   </div>
          );
        })} */}
      {/* 
      {itemList.itemList
        .filter(item => item.Lvl <= 55)
        .map(sortedItem => {
          console.log(sortedItem);
          <UnlockItemCard item={sortedItem.uName} lvl={sortedItem.lvl} />;
        })} */}

      {/* {itemList.itemList
        .filter(item => item.Lvl <= 55)
        .forEach(sortedItem => {
          //   console.log(sortedItem);
          <UnlockItemCard item={sortedItem.uName} lvl={sortedItem.lvl} />;
        })} */}

      {/* {sortItemList(itemList.itemList)} */}
      {/* {itemList.itemList.map(item => {
        item.Lvl >= 55 && <div>{item.uName}</div>;
      })} */}
    </div>
  );
};

export default Unlocks;
