import React from 'react';
import { useItemList } from '../../context/ItemListContext';

// Components
import UnlockItemCard from './UnlockItemCard';

const UnlockLayout = () => {
  const itemList = useItemList();

  const sortItemList = () => {
    const result = itemList.itemList.filter(item => item.Lvl <= 55);

    // result.sort((a, b) => {
    //   return a[0] - b[0];
    // });

    console.log(result);

    // console.log(result);
    // console.log(result);

    // return result.map(item, index => {
    //   <UnlockItemCard item={item} key={index} />;
    //   //   <div className='flex '>
    //   //     <h1 className='mr-2'>{item.Lvl}</h1>
    //   //     {item.uName}
    //   //   </div>;
    // });
  };

  return (
    <div>
      <h2>UnlockLayout</h2>

      <section className='grid grid-cols-6 gap-1'>
        {itemList.itemList
          .filter(item => item.Lvl <= 55)
          // .sort((a, b) => a[0] - b[0])
          .sort((a, b) => {
            return a.Lvl - b.Lvl;
          })
          .map(item => {
            return item.uName !== 'Empty' && <UnlockItemCard item={item} />;
          })}

        {/* {sortItemList()} */}
        {/* {itemList.itemList
          .filter(item => item.Lvl <= 55)
          .map(item => {
            // return item.uName !== 'Empty' && <UnlockItemCard item={item} />;
          })
          .sort((a, b) => console.log(a, b))} */}
      </section>
    </div>
  );
};

export default UnlockLayout;
