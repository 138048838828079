// import React, { useContext, useState } from 'react';

// const AuthContext = React.createContext();
// const AuthUpdateContext = React.createContext();

// // Export custom useContext Hooks
// export function useAuth() {
//   return useContext(AuthContext);
// }

// export function useAuthUpdate() {
//   return useContext(AuthUpdateContext);
// }

// export function AuthProvider({ children }) {
//   const [isAuthenticated, setIsAuthenticated] = useState('Hey this is Context');
//   console.log(isAuthenticated);

//   function updateAuth() {
//     console.log(isAuthenticated);
//     setIsAuthenticated(authStatus => !authStatus);
//   }

//   return (
//     <AuthContext.Provider value={isAuthenticated}>
//       <AuthUpdateContext value={updateAuth}>{children}</AuthUpdateContext>
//     </AuthContext.Provider>
//   );
// }

import React, { Component, createContext, useContext } from 'react';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

class AuthContextProvider extends Component {
  state = {
    isAuthenticated: false,
  };
  toggleAuth = () => {
    this.setState({ isAuthenticated: !this.state.isAuthenticated });
  };
  render() {
    return (
      <AuthContext.Provider
        value={{ ...this.state, toggleAuth: this.toggleAuth }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextProvider;
